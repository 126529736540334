import headbreaker, { Canvas, painters } from "headbreaker";
import { useEffect, useRef } from "react";
import { loadImage } from "../utils";

export function PuzzleQuestionView({ id, imageUrl, onComplete }) {
  const puzzleRef = useRef(null);
  useEffect(() => {
    (async () => {
      let img = await loadImage(imageUrl);

      const puzzle = puzzleRef.current;
      const canvas = setupCanvas(puzzle.id, img, onComplete);
      canvas.draw();
    })();
  }, [id]);

  return (
    <div>
      <div ref={puzzleRef} id={id}></div>
    </div>
  );
}

function setupCanvas(id, image, onComplete) {
  const canvas = new Canvas(id, {
    width: 330,
    height: 300,
    pieceSize: 65,
    proximity: 18,
    strokeWidth: 0.2,
    image: image,

    outline: new headbreaker.outline.Rounded(),
    painter: new painters.Konva(),
    preventOffstageDrag: true,
  });
  canvas.adjustImagesToPuzzleHeight();
  canvas.autogenerate({
    horizontalPiecesCount: 3,
    verticalPiecesCount: 3,
    insertsGenerator: headbreaker.generators.flipflop,
  });
  canvas.shuffle(0.8);

  canvas.attachSolvedValidator();
  canvas.onValid(() => {
    onComplete();
    // setTimeout(() => {}, 100);
  });

  return canvas;
}
