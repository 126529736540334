import { talk, talkMath } from "../speaker/Speaker";
import { getRandomEquation } from "../utils";

export function NumberCompareQuestion({ id = crypto.randomUUID() }) {
  const equation1 = getRandomEquation(50, 50);
  const equation2 = getRandomEquation(50, 50);

  function isCorrect(answer) {
    const result = getResult();
    return result == answer.replaceAll(" ", "");
  }

  function getResult() {
    if (equation1.result < equation2.result) {
      return "<";
    } else if (equation1.result > equation2.result) {
      return ">";
    }
    return "=";
  }

  function getEquationText(equation) {
    const { num1, operationSign, num2 } = equation;
    return `${num1} ${operationSign} ${num2}`;
  }

  function getQuestion() {
    return `${getEquationText(equation1)} [ ] ${getEquationText(equation2)}`;
  }

  function getHints() {
    return ["Complete the equation"];
  }

  function renderHints(className, onClick) {
    return getHints().map((hint, id) => (
      <div
        key={`hint-${id}`}
        className={`hint ${className}`}
        onClick={() => onClick(hint)}
      >
        {hint}
      </div>
    ));
  }

  function renderQuestion(className, onClick) {
    return <div className="questionBubble bubble">{getQuestion()}</div>;
  }

  async function read() {
    await talk(getHints()[0]);
    await talkMath(getQuestion().replace("[ ]", "vs"));
  }

  return {
    id,
    type: "DRAFT_QUESTION",
    isCorrect,
    getQuestion,
    getHints,
    renderHints,
    renderQuestion,
    read,
  };
}
