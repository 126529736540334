import { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { getRandomItem, getRandomItems } from "../utils";

let chart;
function toHData(x, y) {
  const data = [];
  for (var i = 1; i <= y; i++) {
    data.push({
      x: i,
      y: x,
    });
  }
  return data;
}

function getImg(filename) {
  const img = new Image(20, 20);
  img.src = `/assets/animals/${filename}`;
  return img;
}

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function barConfig(data) {
  const labels = Object.keys(data);
  const backgroundColor = getRandomItems(
    [
      "rgba(255, 99, 132)",
      "rgba(255, 159, 64)",
      "rgba(255, 205, 86)",
      "rgba(75, 192, 192)",
      "rgba(54, 162, 235)",
      "rgba(153, 102, 255)",
    ],
    3
  );
  const dataValue = labels.map((label) => data[label]);

  return {
    type: "bar",
    data: {
      labels: labels,
      datasets: [
        {
          axis: "y",
          data: dataValue,
          borderWidth: 1,
          backgroundColor: backgroundColor,
        },
      ],
    },
    options: {
      indexAxis: "y",
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          ticks: {
            stepSize: 1,
          },
        },
      },
    },
  };
}

function scatterConfig(data) {
  const datasets = [];
  for (const field in data) {
    datasets.push({
      data: toHData(capitalize(field), data[field]),
      pointStyle: getImg(`${field}.png`),
    });
  }

  return {
    type: "scatter",
    data: { datasets },
    options: {
      responsive: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          ticks: {
            stepSize: 1,
          },
          offset: true,
          grid: {
            display: false,
          },
        },
        y: {
          type: "category",
          offset: true,
          grid: {
            offset: true,
          },
        },
      },
    },
  };
}

function setupChart(element, config) {
  if (chart) {
    chart.destroy();
  }

  chart = new Chart(element, config);
}

export default function ChartQuestionView({ data }) {
  const chartConfig = getRandomItem([barConfig, scatterConfig]);

  const chartRef = useRef(null);
  useEffect(() => {
    setupChart(chartRef.current, chartConfig(data));
  }, [data]);

  return (
    <canvas
      ref={chartRef}
      id="horizontalChart"
      width="400px"
      height="160px"
    ></canvas>
  );
}
