const NUMBER_WORDS = [
  "zero",
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine",
  "ten",
  "eleven",
  "twelve",
  "thirteen",
  "fourteen",
  "fifteen",
  "sixteen",
  "seventeen",
  "eighteen",
  "nineteen",
];
const TEN_WORDS = [
  "twenty",
  "thirty",
  "forty",
  "fifty",
  "sixty",
  "seventy",
  "eighty",
  "ninety",
];

export function number2words(n) {
  if (n < 20) return NUMBER_WORDS[n];
  var digit = n % 10;
  if (n < 100)
    return TEN_WORDS[~~(n / 10) - 2] + (digit ? "-" + NUMBER_WORDS[digit] : "");
  if (n < 1000)
    return (
      NUMBER_WORDS[~~(n / 100)] +
      " hundred" +
      (n % 100 == 0 ? "" : " and " + number2words(n % 100))
    );
  return (
    number2words(~~(n / 1000)) +
    " thousand" +
    (n % 1000 != 0 ? " " + number2words(n % 1000) : "")
  );
}

export function getRandomEquation(maxNum1, maxNum2) {
  const num1 = getRandomInt(maxNum1, 10);
  const operation = getRandomOperation([plus, minus]);
  const operationSign = operation === minus ? "-" : "+";
  const num2 = getRandomInt(operationSign === "-" ? num1 : maxNum2, 1);
  const result = operation(num1, num2);
  return {
    num1,
    operation,
    operationSign,
    num2,
    result,
  };
}

export function plus(a, b) {
  return a + b;
}

export function minus(a, b) {
  return a - b;
}

export function getRandomItem(items) {
  const index = getRandomInt(items.length);
  return items[index];
}

export function getRandomItems(items, num) {
  const output = [];
  let cloned = [...items];
  for (let i = 0; i < num; i++) {
    const index = getRandomInt(cloned.length);
    const item = cloned[index];
    output.push(item);
    cloned.splice(index, 1);

    if (cloned.length === 0) {
      break;
    }
  }
  return output;
}

export function getRandomOperation(operations) {
  // const enabled = Object.keys(operations).reduce((init, cur) => {
  //   if (operations[cur]) {
  //     init.push(cur);
  //   }
  //   return init;
  // }, []);

  const ranIndex = getRandomInt(operations.length);
  return operations[ranIndex];
}

export function getRandomInt(max, min = 0) {
  const num = Math.floor(Math.random() * (max - min) + min);
  return num;
}

export function getRandomIntExcept(max, min = 0, except) {
  const num = getRandomInt(max, min);
  return num === except ? num + 1 : num;
}

export function getResult(op, num1, num2) {
  switch (op) {
    case "+":
      return num1 + num2;
    case "-":
      return num1 - num2;
    case "*":
      return num1 * num2;
    case "/":
      return num1 / num2;
  }
}

export function withUnit(num, unit) {
  if (unit.length === 0) {
    return num;
  }
  return `${num} ${unit}`;
}

export function pluralUnit(num, unit, numberFormatter) {
  const unitWord = `${unit + (num > 1 ? "s" : "")}`;
  return `${numberFormatter ? numberFormatter(num) : num} ${unitWord}`;
}

export function animate(elem, animation, duration) {
  elem.classList.add(animation);
  return new Promise((resolve) => {
    setTimeout(() => {
      elem.classList.remove(animation);
      resolve();
    }, duration);
  });
  // resolve, t, val));

  setTimeout(() => {}, duration);
  // return new Promise((resolve, reject) => {
  //   function handleAnimationEnd() {
  //     console.log("animation ended...");
  //     elem.classList.remove(animation);
  //     resolve(elem);
  //   }
  //   elem.addEventListener("animationend", handleAnimationEnd, { once: true });
  //   elem.classList.add(animation);
  // });
}

export function similarity(s1, s2) {
  var longer = s1;
  var shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  var longerLength = longer.length;
  if (longerLength == 0) {
    return 1.0;
  }
  return (
    (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength)
  );
}

function editDistance(s1, s2) {
  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();

  var costs = new Array();
  for (var i = 0; i <= s1.length; i++) {
    var lastValue = i;
    for (var j = 0; j <= s2.length; j++) {
      if (i == 0) costs[j] = j;
      else {
        if (j > 0) {
          var newValue = costs[j - 1];
          if (s1.charAt(i - 1) != s2.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
    }
    if (i > 0) costs[s2.length] = lastValue;
  }
  return costs[s2.length];
}

export function loadImage(url) {
  /*
   * We are going to return a Promise which, when we .then
   * will give us an Image that should be fully loaded
   */
  return new Promise((resolve) => {
    /*
     * Create the image that we are going to use to
     * to hold the resource
     */
    const image = new Image();
    /*
     * The Image API deals in even listeners and callbacks
     * we attach a listener for the "load" event which fires
     * when the Image has finished the network request and
     * populated the Image with data
     */
    image.addEventListener("load", () => {
      /*
       * You have to manually tell the Promise that you are
       * done dealing with asynchronous stuff and you are ready
       * for it to give anything that attached a callback
       * through .then a realized value.  We do that by calling
       * resolve and passing it the realized value
       */
      resolve(image);
    });
    /*
     * Setting the Image.src is what starts the networking process
     * to populate an image.  After you set it, the browser fires
     * a request to get the resource.  We attached a load listener
     * which will be called once the request finishes and we have
     * image data
     */
    image.src = url;
  });
}

export function shuffle(array) {
  let currentIndex = array.length;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    let randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
}
