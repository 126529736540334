import MemoryCard from "../../component/memory-card/src/MemoryCard";
import { talk } from "../../speaker/Speaker";
import { getRandomInt, getRandomItems } from "../../utils";

const images = [
  "ant",
  "bat",
  "bee",
  "bird",
  "chameleon",
  "chicken",
  "crab",
  "cricket",
  "dragonfly",
  "duck",
  "eagle",
  "elephant",
  "fish",
  "flamingo",
  "frog",
  "giraffe",
  "hummingbird",
  "kitty",
  "koala",
  "octopus",
  "parrot",
  "peacock",
  "shark",
  "snail",
  "snake",
  "starfish",
  "stingray",
  "turtle",
  "unicorn",
  "whale",

  "bear-1",
  "bluebird",
  "boba-tea",
  "cake",
  "candy",
  "christmas-bag",
  "christmas-tree",
  "coin",
  "cola",
  "crayon",
  "crown",
  "dino-birthday",
  "dino-dizzy",
  "dino-sick",
  "donut",
  "dumpling",
  "earth",
  "fast-food",
  "fox",
  "frog-kid",
  "gems",
  "gummy-bear",
  "ice-cream",
  "kitty-computer",
  "kitty-dazed",
  "kitty-hello",
  "kitty-hero",
  "kitty-scream",
  "lion",
  "lollipop",
  "lucky-cat",
  "mercat",
  "money",
  "noodles",
  "plant",
  "poop",
  "rainbow",
  "rice",
  "santa",
  "snowman",
  "storm",
  "sun-2",
  "sun",
  "sushi",
  "wolf",
  "worm",
];

export function MemoryCardQuestion({ id = crypto.randomUUID() }) {
  let result = false;
  let items = getItems();

  function getItems() {
    const numberOfItems = getRandomInt(7, 4);
    const items = getRandomItems(images, numberOfItems);
    return items.map((item) => {
      return `/assets/animals/${item}.png`;
    });
  }

  function isCorrect() {
    const result = getResult();
    return result;
  }

  function getResult() {
    return result;
  }

  function getQuestion() {
    return "";
  }

  function getHints() {
    return ["Find matching cards"];
  }

  function setResult() {
    result = true;
  }

  function renderHints(className, onClick) {
    return getHints().map((hint, id) => (
      <div
        key={`hint-${id}`}
        className={`hint ${className}`}
        onClick={() => onClick(hint)}
      >
        {hint}
      </div>
    ));
  }

  function renderQuestion(className, onClick) {
    return (
      <div className="questionBubble bubble">
        <MemoryCard items={items} onComplete={() => setResult()} />
      </div>
    );
  }

  async function read() {
    await talk(getHints()[0]);
  }

  return {
    id,
    type: "NO_INPUT",
    isCorrect,
    getQuestion,
    getHints,
    renderHints,
    renderQuestion,
    read,
  };
}
