export default function NoInput({ children, question, onCheckResult }) {
  async function checkResult() {
    const result = question.isCorrect();

    if (result) {
    }

    onCheckResult(result);
  }

  return (
    <div className="inputPanel">
      <div className="controllerContainer">
        {children}
        <button style={{ background: "orange" }}>
          <i className="fa fa-eraser"></i>
        </button>
        <span style={{ flexGrow: 1 }} />
        <button style={{ background: "orange" }}>
          <i className="fa fa-eraser"></i>
        </button>
        <button
          onClick={checkResult}
          style={{ background: "#b71c1c", color: "white" }}
        >
          <i
            className="fa-solid fa-circle-half-stroke"
            style={{ transform: "rotate(-90deg)" }}
          ></i>
        </button>
      </div>
      <div
        className="canvasContainer"
        style={{ height: 200, opacity: 0 }}
      ></div>
    </div>
  );
}
