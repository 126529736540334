import { getRandomItem } from "../../../utils";
import "./Card.css";

export default function Card({
  onClick,
  id,
  item,
  isFlipped,
  attention = false,
}) {
  const flipDirection = getRandomItem(["left", "right"]);

  return (
    <div
      className={`card animate__animated ${isFlipped ? "flipped" : ""} ${
        attention ? "animate__rubberBand" : ""
      }`}
      onClick={() => onClick(id)}
    >
      <div className="card-inner">
        <div className="card-back"></div>
        <div className="card-front">
          <img src={item} className="frontImage" />
        </div>
      </div>
    </div>
  );
}
