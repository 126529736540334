import { useCallback, useEffect, useRef, useState } from "react";
import Board from "./Board";
import { shuffle } from "../../../utils";

export default function MemoryCard({ items, onComplete }) {
  const [cards, setCards] = useState([]);
  const [firstCard, setFirstCard] = useState(null);
  const [secondCard, setSecondCard] = useState(null);
  const [matches, setMatches] = useState(0);

  const createCards = () => {
    const duplicated = [...items, ...items];
    shuffle(duplicated);

    const newCards = duplicated.map((item) => ({
      item,
      isFlipped: false,
    }));
    setCards(newCards);
  };

  const flipCard = (id) => {
    if (
      cards[id].isFlipped ||
      firstCard === id ||
      secondCard === id ||
      (firstCard !== null && secondCard !== null)
    ) {
      return;
    }

    const newCards = cards.map((card, index) => ({
      ...card,
      isFlipped: index === id ? !card.isFlipped : card.isFlipped,
    }));
    setCards(newCards);

    if (firstCard === null) {
      setFirstCard(id);
    } else if (secondCard === null) {
      setSecondCard(id);
    }
  };

  function reset() {
    setMatches(0);
    setFirstCard(null);
    setSecondCard(null);
  }

  const checkMatch = useCallback(() => {
    if (cards[firstCard].item === cards[secondCard].item) {
      setMatches(matches + 1);
      setFirstCard(null);
      setSecondCard(null);

      // pulse
      setTimeout(() => {
        pulseCards([firstCard, secondCard]);
      }, 600);
    } else {
      setTimeout(() => {
        const newCards = cards.map((card, index) => {
          if (index === firstCard || index === secondCard) {
            return {
              ...card,
              isFlipped: false,
            };
          } else {
            return card;
          }
        });
        setCards(newCards);
        setFirstCard(null);
        setSecondCard(null);
      }, 1000);
    }
  }, [firstCard, secondCard, matches]);

  function pulseCards(cardIds) {
    setCards((preCards) => {
      return preCards.map((card, i) => {
        card.attention = false;
        if (cardIds.includes(i)) {
          card.attention = true;
        }
        return card;
      });
    });
  }

  useEffect(() => {
    createCards();
  }, [items]);

  useEffect(() => {
    if (firstCard !== null && secondCard !== null) {
      checkMatch();
    }
  }, [firstCard, secondCard, checkMatch]);

  useEffect(() => {
    if (cards.length > 0 && matches === cards.length / 2) {
      onComplete();
      reset();
    }
  }, [matches]);

  return (
    <div className="App">
      <Board cards={cards} onCardClick={flipCard} />
    </div>
  );
}
