import { talkMath } from "../speaker/Speaker";
import { getRandomEquation } from "../utils";
import { toNumber } from "./QuestionUtil";
import "./StackingMathQuestion.css";

export function StackingMathQuestion({ id }) {
  const { num1, num2, operationSign, result } = getRandomEquation(100, 100);
  const question = `${num1} ${operationSign} ${num2} = ?`;

  function isCorrect(answer) {
    const num1 = answer.replaceAll(" ", "");
    return getResult() == toNumber(num1);
  }

  function getResult() {
    return result;
  }

  function getQuestion() {
    return question;
  }

  function getHints() {
    return [];
  }

  function renderHints(className, onClick) {
    return getHints().map((hint, id) => (
      <div
        key={`hint-${id}`}
        className={`hint ${className}`}
        onClick={() => onClick(hint)}
      >
        {hint}
      </div>
    ));
  }

  function renderQuestion(className, onClick) {
    return <></>;
  }

  function renderInput() {
    return (
      <div className="stackEquation layer0">
        <div>{num1}</div>
        <div>
          {operationSign}
          {num2}
        </div>
      </div>
    );
  }

  async function read() {
    // await talk(getHints()[0]);
    await talkMath(question);
  }

  return {
    id,
    type: "STACK_INPUT",
    isCorrect,
    getQuestion,
    getHints,
    renderHints,
    renderQuestion,
    read,
    renderInput,
  };
}
