import { talk, talkMath } from "../speaker/Speaker";
import {
  getRandomEquation,
  getRandomInt,
  getRandomItem,
  minus,
  plus,
  withUnit,
} from "../utils";
import ChartQuestionView from "./ChartQuestionView";
import { toNumber } from "./QuestionUtil";

function getRandomPair(array) {
  const cloned = [...array];
  const index1 = getRandomInt(cloned.length);
  const item1 = cloned[index1];
  cloned.splice(index1, 1);

  const index2 = getRandomInt(cloned.length);
  const item2 = cloned[index2];
  cloned.splice(index2, 1);

  return [item1, item2];
}

function generateQuestion(item1, value1, item2, value2) {
  const operation = getRandomItem([plus, minus, minus]);
  const person = getRandomItem(["Moon", "Luna"]);
  const action = getRandomItem(["buy", "sell", "choose", "borrow", "have"]);
  const item = getRandomItem(["sticker", "picture", "", "card", "toy"]);

  let question;
  if (operation === plus) {
    question = `How many ${withUnit(item1, item)} and ${withUnit(
      item2,
      item
    )} did ${person} ${action} in total?`;
  } else {
    const morefewer = value1 > value2 ? "more" : "fewer";
    question = `How many ${morefewer} ${withUnit(
      item1,
      item
    )} did ${person} ${action} than ${withUnit(item2, item)}?`;
  }
  const result = Math.abs(operation(value1, value2));
  return {
    question,
    result,
  };
}

export function ChartQuestion({ id = crypto.randomUUID() }) {
  const data = getAnimalData();
  const [item1, item2] = getRandomPair(Object.keys(data));
  const num1 = data[item1];
  const num2 = data[item2];

  const { question, result } = generateQuestion(item1, num1, item2, num2);

  function isCorrect(answer) {
    return getResult() == toNumber(answer.replaceAll(" ", ""));
  }

  function getResult() {
    return result;
  }

  function getQuestion() {
    return `question`;
  }

  function getHints() {
    return [question];
  }

  function renderHints(className, onClick) {
    return getHints().map((hint, id) => (
      <div
        key={`hint-${id}`}
        className={`hint ${className}`}
        onClick={() => onClick(hint)}
      >
        {hint}
      </div>
    ));
  }

  function getAnimalData() {
    const animals = [
      "ant",
      "bat",
      "bee",
      "bird",
      "chameleon",
      "chicken",
      "crab",
      "cricket",
      "dragonfly",
      "duck",
      "eagle",
      "elephant",
      "fish",
      "flamingo",
      "frog",
      "giraffe",
      "hummingbird",
      "kitty",
      "koala",
      "octopus",
      "parrot",
      "peacock",
      "shark",
      "snail",
      "snake",
      "starfish",
      "stingray",
      "turtle",
      "unicorn",
      "whale",
    ];
    const clonedItems = [...animals];

    const index1 = getRandomInt(clonedItems.length);
    const animal1 = clonedItems[index1];
    clonedItems.splice(index1, 1);

    const index2 = getRandomInt(clonedItems.length);
    const animal2 = clonedItems[index2];
    clonedItems.splice(index2, 1);

    const index3 = getRandomInt(clonedItems.length);
    const animal3 = clonedItems[index3];
    clonedItems.splice(index3, 1);

    return {
      [animal1]: getRandomInt(12, 1),
      [animal2]: getRandomInt(12, 1),
      [animal3]: getRandomInt(12, 1),
    };
  }

  function renderQuestion(className, onClick) {
    return (
      <div className="questionBubble bubble">
        <ChartQuestionView data={data} />
      </div>
    );
  }

  async function read() {
    await talk(getHints()[0]);
  }

  return {
    id,
    type: "DRAFT_QUESTION",
    isCorrect,
    getQuestion,
    getHints,
    renderHints,
    renderQuestion,
    read,
  };
}
