import Card from "./Card";
import "./Board.css";

export default function Board({ cards, onCardClick }) {
  return (
    <div className="board">
      {cards.map((card, index) => (
        <Card
          key={index}
          id={index}
          item={card.item}
          isFlipped={card.isFlipped}
          attention={card.attention}
          onClick={onCardClick}
        />
      ))}
    </div>
  );
}
