import { talk, talkMath } from "../speaker/Speaker";
import { getRandomInt, getRandomOperation, minus, plus } from "../utils";
import { toNumber } from "./QuestionUtil";

export function EquationQuestion({ id = crypto.randomUUID() }) {
  const num1 = getRandomInt(100, 10);
  const operation = getRandomOperation([plus, minus]);
  const num2 = getRandomInt(operation === minus ? num1 : 100);
  const result = operation(num1, num2);

  function isCorrect(answer) {
    return getResult() == toNumber(answer.replaceAll(" ", ""));
  }

  function getResult() {
    return num2;
  }

  function getQuestion() {
    return `${num1} ${operation === minus ? "-" : "+"} ____ = ${result}`;
  }

  function getHints() {
    return ["Complete the equation"];
  }

  function renderHints(className, onClick) {
    return getHints().map((hint, id) => (
      <div
        key={`hint-${id}`}
        className={`hint ${className}`}
        onClick={() => onClick(hint)}
      >
        {hint}
      </div>
    ));
  }

  function renderQuestion(className, onClick) {
    return <div className="questionBubble bubble">{getQuestion()}</div>;
  }

  async function read() {
    await talk(getHints()[0]);
    await talkMath(getQuestion());
  }

  return {
    id,
    type: "EQUATION_QUESTION",
    isCorrect,
    getQuestion,
    getHints,
    renderHints,
    renderQuestion,
    read,
  };
}
