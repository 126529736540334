import { useEffect, useRef, useState } from "react";
import "./App.css";
import Config from "./component/Config.jsx";
import Winning from "./component/Winning.jsx";
import InputPanel from "./component/InputPanel.jsx";
import PokemonContainer from "./component/PokemonContainer.jsx";
import { Pokemon } from "./pokemon/Pokemon.js";
import { getRandomInt, getRandomItem } from "./utils.js";
import Pokebag from "./component/Pokebag.jsx";
import Fighter from "./component/Fighter.jsx";
import { RandomMathQuestion } from "./question/RandomMathQuestion.jsx";
import MathInput from "./component/MathInput.jsx";
import { TextQuestion } from "./question/TextQuestion.jsx";
import TextInput from "./component/TextInput.jsx";
import { GrammarQuestion } from "./question/GrammarQuestion.jsx";
import PokemonTalk from "./component/PokemonTalk.jsx";
import { TimeQuestion } from "./question/TimeQuestion.jsx";
import { v4 as uuidv4, v4 } from "uuid";
import { VocabularyQuestion } from "./question/VocabularyQuestion.jsx";
import { SpellingQuestion } from "./question/SpellingQuestion.jsx";
import { getRandomSpellingWord } from "./question/QuestionUtil.jsx";
import { MathWordQuestion } from "./question/MathWordQuestion.jsx";
import DraftInput from "./component/DraftInput.jsx";
import { EquationQuestion } from "./question/EquationQuestion.jsx";
import { NumberCompareQuestion } from "./question/NumberCompareQuestion.jsx";
import { ChartQuestion } from "./question/ChartQuestion.jsx";
import { MoneyQuestion } from "./question/MoneyQuestion.jsx";
import { StackingMathQuestion } from "./question/StackingMathQuestion.jsx";
import StackInput from "./component/StackInput.jsx";
import { PuzzleQuestion } from "./question/PuzzleQuestion.jsx";
import NoInput from "./component/NoInput.jsx";
import { MemoryCardQuestion } from "./question/MemoryCardQuestion/MemoryCardQuestion.jsx";

let currentQuestionId;

function App() {
  const [questions, setQuestions] = useState([]);

  const [operations, setOperations] = useState(["+", "-"]);
  const [pokemon, setPokemon] = useState();
  const [maxNumber, setMaxNumber] = useState(300);
  const [total, setTotal] = useState(30);
  const [solved, setSolved] = useState(0);
  const [pokebag, setPokebag] = useState([]);
  const [isCatching, setIsCatching] = useState(false);
  const [status, setStatus] = useState("IDLE");

  const moveRef = useRef();

  const [currentQuestion, setCurrentQuestion] = useState();
  // const currentQuestion = questions[questions.length];\

  const [lineIndex, setLineIndex] = useState();
  const [lines, setLines] = useState([]);
  const [poemIndex, setPoemIndex] = useState(0);
  const [poems, setPoems] = useState([]);

  const [grammarQuestions, setGrammarQuestions] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await fetch("/assets/poem/poem.json");
      const newPoems = await response.json();
      const shuffled = newPoems
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);
      setPoems(shuffled);

      setPoemIndex(0);
      setLineIndex(0);
    })();

    (async () => {
      const response = await fetch("/assets/grammar/grammar.json");
      const newGrammarQuestions = await response.json();
      setGrammarQuestions(newGrammarQuestions);
    })();
  }, []);

  function getNextQuestion() {
    speechSynthesis.cancel();

    const bank = [
      [getMoneyQuestion, 2],
      [getGrammarQuestion, 1],
      [getTextQuestion, 1],
      [getTimeQuestion, 1],
      [getSpellingQuestion, 4],
      [getMathQuestion, 2],
      [getEquationQuestion, 3],
      [getNumberCompareQuestion, 2],
      [getChartQuestion, 2],
      [getMathWordQuestion, 3],
      [getStackingMathQuestion, 2],
      [getPuzzleQuestion, 1],
      [getMemoryCardQuestion, 1],
    ];

    const bankOutput = bank.reduce((prev, bank) => {
      for (let i = 0; i < bank[1]; i++) {
        prev.push(bank[0]);
      }
      return prev;
    }, []);

    const question = getRandomItem(bankOutput)();

    setQuestions([...questions, question]);
    setCurrentQuestion(question);
  }

  function getMathQuestion() {
    const mathQuestion = RandomMathQuestion({
      operations,
      maxValue: maxNumber,
      onCheckResult: checkResult,
    });
    return mathQuestion;
  }

  function getEquationQuestion() {
    const question = EquationQuestion({
      onCheckResult: checkResult,
    });
    return question;
  }

  function getNumberCompareQuestion() {
    const question = NumberCompareQuestion({});
    return question;
  }

  function getPuzzleQuestion() {
    const question = PuzzleQuestion({});
    return question;
  }

  function getMemoryCardQuestion() {
    const question = MemoryCardQuestion({});
    return question;
  }

  function getGrammarQuestion() {
    const question = grammarQuestions[getRandomInt(0, grammarQuestions.length)];
    const q = GrammarQuestion({
      question: question,
      onCheckResult: checkResult,
    });
    return q;
  }

  function getChartQuestion() {
    const q = ChartQuestion({});
    return q;
  }

  function getMathWordQuestion() {
    const q = MathWordQuestion({
      onCheckResult: checkResult,
    });
    return q;
  }

  function getMoneyQuestion() {
    const q = MoneyQuestion({});
    return q;
  }

  function getStackingMathQuestion() {
    const q = StackingMathQuestion({ id: crypto.randomUUID() });
    return q;
  }

  function getTextQuestion() {
    const currentPoem = poems[poemIndex];
    const textQuestion = TextQuestion({
      poem: currentPoem,
      lineIndex: lineIndex,
      onCheckResult: checkResult,
    });
    if (lineIndex === currentPoem.length - 1) {
      setLineIndex(0);
      setPoemIndex(poemIndex + 1);
    } else {
      setLineIndex(lineIndex + 1);
    }
    return textQuestion;
  }

  function getTimeQuestion() {
    const hour = getRandomInt(12, 1);
    const minute = getRandomInt(12, 1) * 5;
    const question = TimeQuestion({
      id: uuidv4(),
      hour,
      minute,
    });
    return question;
  }

  function getSpellingQuestion() {
    const question = SpellingQuestion({
      id: uuidv4(),
      word: getRandomSpellingWord(),
    });
    return question;
  }

  function getVocabularyQuestion() {
    const question = VocabularyQuestion({
      // id: uuidv4(),
      onResultChange: handleVocabularyResultChange,
    });
    return question;
  }

  function handleVocabularyResultChange(result) {
    renderCurrentQuestion();
  }

  function onConfigChange(nextConfig) {
    setOperations(nextConfig.operations);
    setMaxNumber(nextConfig.maxNumber);
    setTotal(+nextConfig.total);
  }

  function getNextPokemon() {
    const pid = getRandomInt(600, 1);
    const nextPokemon = Pokemon(pid);
    setPokemon(nextPokemon);
    nextPokemon.cry();
  }

  function newQuestion() {
    getNextQuestion();
    getNextPokemon();
  }

  function checkResult(isCorrect) {
    setIsCatching(!isCatching);

    pokemon?.cry();
    setTimeout(() => {
      handlingStatus(isCorrect);
      if (isCorrect) {
        setSolved(solved + 1);
        setPokebag([pokemon, ...pokebag]);
        if (solved < total) {
          newQuestion();
        }
      }
    }, 2200);
  }

  async function handlingStatus(isCorrect) {
    let time = isCorrect ? 2000 : 1500;
    if (!isCorrect && currentQuestion.type === "SPELLING_QUESTION") {
      await currentQuestion.read();
    }
    setStatus(isCorrect ? "WIN" : "LOOSE");
    setTimeout(() => {
      setStatus("IDLE");
    }, time);
  }

  useEffect(() => {
    if (moveRef.current) {
      move({ steps: 4, startIndex: 10 }, 10);
    }
  }, []);

  function move(
    { steps, startIndex = 1, size = 5 },
    repeat = 1,
    reverse = false
  ) {
    let count = 0;
    let start = startIndex - 1;
    let movesPos = [];
    let status = setInterval(() => {
      let x = (start + count) % size;
      let y = Math.floor((start + count) / size);
      let pos = `${-x * 192}px ${-y * 192}px`;
      movesPos.push(pos);
      showMovePos(pos);
      count++;

      if (count === steps) {
        repeat--;
        if (repeat > 0) {
          count = 0;
        } else {
          moveRef.current.style.backgroundPosition = "-10000px";
          clearInterval(status);
        }
        if (reverse) {
          startReverse();
        }
      }
    }, 100);

    function startReverse() {
      let reverseInterval = setInterval(() => {
        let pos = movesPos.pop();
        showMovePos(pos);
        if (movesPos.length === 0) {
          showMovePos("-10000px");
          clearInterval(reverseInterval);
        }
      }, 80);
    }

    function showMovePos(pos) {
      moveRef.current.style.backgroundPosition = pos;
    }
  }

  function renderMathQuestion(question) {
    return (
      <MathInput question={question} onCheckResult={checkResult}>
        {getPlayButton()}
      </MathInput>
    );
  }

  function renderDraftInput(question) {
    return (
      <DraftInput question={question} onCheckResult={checkResult}>
        {getPlayButton()}
      </DraftInput>
    );
  }

  function renderTextQuestion(question) {
    return (
      <TextInput question={question} onCheckResult={checkResult}>
        {getPlayButton()}
      </TextInput>
    );
  }

  function renderGrammarQuestion(question) {
    return (
      <TextInput question={question} onCheckResult={checkResult}>
        {getPlayButton()}
      </TextInput>
    );
  }

  function renderStackInput(question) {
    return (
      <StackInput question={question} onCheckResult={checkResult}>
        {getPlayButton()}
      </StackInput>
    );
  }

  function renderNoInput(question) {
    return (
      <NoInput question={question} onCheckResult={checkResult}>
        {getPlayButton()}
      </NoInput>
    );
  }

  function getPlayButton() {
    return (
      <button
        onClick={newQuestion}
        style={{ background: "green", color: "white" }}
      >
        <i className="fa-solid fa-play"></i>
      </button>
    );
  }

  function renderCurrentQuestion() {
    if (currentQuestionId !== currentQuestion?.id && currentQuestion?.read) {
      currentQuestionId = currentQuestion.id;
      setTimeout(() => {
        currentQuestion.read();
      }, 1000);
    }

    switch (currentQuestion?.type) {
      case "TEXT_QUESTION":
        return renderTextQuestion(currentQuestion);
      case "GRAMMAR_QUESTION":
        return renderGrammarQuestion(currentQuestion);
      case "MATH_QUESTION":
        return renderMathQuestion(currentQuestion);
      case "MATH_WORD_QUESTION":
      case "EQUATION_QUESTION":
      case "DRAFT_QUESTION":
        return renderDraftInput(currentQuestion);
      case "TIME_QUESTION":
        return renderTextQuestion(currentQuestion);
      case "SPELLING_QUESTION":
        return renderTextQuestion(currentQuestion);
      case "VOCABULARY_QUESTION":
        return renderTextQuestion(currentQuestion);
      case "STACK_INPUT":
        return renderStackInput(currentQuestion);
      case "NO_INPUT":
        return renderNoInput(currentQuestion);
      default:
        return getPlayButton();
    }
  }

  return (
    <div className="app">
      <Config
        maxNumber={maxNumber}
        operations={operations}
        solved={solved}
        total={total}
        onChange={onConfigChange}
      />
      <div id="centerContainer">
        {/* <div ref={moveRef} className="move"></div> */}
        {solved === total ? (
          <Winning pokemons={pokebag} />
        ) : (
          <PokemonContainer
            isCatching={isCatching}
            pokemon={pokemon}
            question={currentQuestion}
          >
            <PokemonTalk
              question={currentQuestion}
              showAnswer={status === "LOOSE"}
            />
            {/* )} */}
          </PokemonContainer>
        )}
        <Fighter status={status} />
      </div>
      <div className="footer">
        {renderCurrentQuestion()}
        {/* {renderTextQuestion(currentQuestion)} */}
        {/* <InputPanel
          maxNumber={maxNumber}
          operations={operations}
          onStart={newQuestion}
          onCheckResult={checkResult}
        /> */}
        <Pokebag pokebag={pokebag} visible={total !== solved} />
      </div>
    </div>
  );
}

export default App;
