import { getRandomInt, getRandomIntExcept } from "../utils";

export function getWords() {
  const words = [
    ["Absent", "He was absent today."],
    ["Accept", "She couldn't accept the gift."],
    ["Act", "Act in the play."],
    ["Adapt", "They had to adapt quickly."],
    ["Add", "Add some sugar to the tea."],
    ["Addition", "In addition, he likes tea."],
    ["Adjust", "He adjusted his glasses."],
    ["Affordable", "The price is affordable."],
    ["After", "We can go out after dinner."],
    ["Afternoon", "See you this afternoon."],
    ["Again", "Let's try again."],
    ["Agree", "I agree with you."],
    ["Air", "The air is fresh."],
    ["Airport", "The airport is busy."],
    ["Alarm", "The alarm woke us up."],
    ["Alike", "The twins look alike."],
    ["Alligator", "The alligator is in the water."],
    ["Almonds", "Almonds are healthy snacks."],
    ["Alone", "She felt alone in the crowd."],
    ["Along", "We walked along the beach."],
    ["Also", "She is also coming."],
    ["Always", "She always smiles."],
    ["Am", "I am here."],
    ["Animal", "The animal is friendly."],
    ["Another", "I want another cookie."],
    ["Answer", "He gave the right answer."],
    ["Any", "Do you have any questions?"],
    ["Anyone", "Can anyone help me?"],
    ["Anyway", "Let's go anyway."],
    ["Apologize", "He apologized for being late."],
    ["April", "April brings spring flowers."],
    ["Are", "They are friends."],
    ["Arm", "Lift your arm."],
    ["Around", "Look around you."],
    ["Arrive", "They will arrive soon."],
    ["Artistic", "She has an artistic talent."],
    ["Ask", "Can I ask you something?"],
    ["Atmosphere", "The atmosphere was tense."],
    ["Attach", "Please attach the file."],
    ["Attention", "Pay attention in class."],
    ["Attract", "Flowers attract bees."],
    ["August", "August is very hot."],
    ["Autumn", "The leaves change color in autumn."],
    ["Award", "She won an award."],
    ["Aware", "Be aware of the rules."],
    ["Away", "The cat ran away."],
    ["Baboon", "The baboon climbed the tree."],
    ["Back", "She came back home."],
    ["Backpack", "Her backpack was heavy."],
    ["Bad", "That was a bad idea."],
    ["Bakery", "The bakery smells wonderful."],
    ["Balance", "He lost his balance."],
    ["Ball", "The ball bounces."],
    ["Bandage", "He put on a bandage."],
    ["Banner", "The banner is red."],
    ["Bare", "He walked barefoot."],
    ["Barn", "The barn is full of hay."],
    ["Base", "The base is strong."],
    ["Basket", "The basket was full of fruit."],
    ["Batch", "She baked a batch of cookies."],
    ["Bath", "She took a bath."],
    ["Beach", "The beach is sunny."],
    ["Beak", "The bird's beak"],
    ["Bear", "The bear is strong."],
    ["Because", "I stayed home because it was raining."],
    ["Bed", "The bed is comfortable."],
    ["Been", "I have been there before."],
    ["Before", "Finish your work before you play."],
    ["Begin", "Let's begin the game."],
    ["Believe", "I believe in magic."],
    ["Belt", "His belt was too tight."],
    ["Bend", "He bent down to pick it up."],
    ["Beneath", "The cat hid beneath the bed."],
    ["Besides", "Besides, it's fun."],
    ["Best", "She is my best friend."],
    ["Better", "This is a better option."],
    ["Between", "The cat is between the chairs."],
    ["Beyond", "The stars are beyond reach."],
    ["Bicycle", "I ride my bicycle."],
    ["Bird", "The bird is singing."],
    ["Black", "The cat is black."],
    ["Blend", "Blend the ingredients well."],
    ["Blink", "She blinked in surprise."],
    ["Bloom", "The flowers bloom in the garden."],
    ["Blue", "The sky is blue."],
    ["Blurry", "The photo was blurry."],
    ["Board", "The board is white."],
    ["Boast", "He likes to boast."],
    ["Boat", "The boat sailed smoothly."],
    ["Bolt", "The door was bolted shut."],
    ["Book", "Read this book."],
    ["Borrow", "Can I borrow a pen?"],
    ["Both", "Both options are good."],
    ["Bottle", "Can you pass the bottle?"],
    ["Bottom", "Look at the bottom."],
    ["Bought", "She bought a new dress."],
    ["Bounce", "The ball can bounce."],
    ["Bowl", "The bowl is empty."],
    ["Boy", "The boy is playing."],
    ["Brain", "Use your brain."],
    ["Branch", "The branch broke."],
    ["Brave", "She is very brave."],
    ["Break", "Don't break the vase."],
    ["Breakfast", "Breakfast is important."],
    ["Bright", "The sun is bright."],
    ["Bring", "Bring your book."],
    ["Brother", "My brother is funny."],
    ["Brown", "The dog is brown."],
    ["Bundle", "He carried a bundle of sticks."],
    ["But", "I like it, but it's expensive."],
    ["Butter", "She spread butter on toast."],
    ["Buy", "I will buy some apples."],
    ["Cabin", "They stayed in a cozy cabin."],
    ["Cage", "The bird is in a cage."],
    ["Calendar", "Mark the date on the calendar."],
    ["Call", "Please call me later."],
    ["Called", "She called me."],
    ["Calm", "Stay calm and relax."],
    ["Camel", "The camel has humps."],
    ["Cannot", "I cannot do it."],
    ["Care", "She takes good care of her plants."],
    ["Career", "He has a good career."],
    ["Careful", "Be careful with the glass."],
    ["Carry", "Carry the bag carefully."],
    ["Cartoon", "I love watching cartoons."],
    ["Castle", "The castle is old."],
    ["Caterpillar", "The caterpillar turned into a butterfly."],
    ["Caution", "Use caution when crossing the street."],
    ["Ceiling", "The ceiling is high."],
    ["Celebrate", "Let's celebrate your birthday."],
    ["Cell", "The cell is small."],
    ["Center", "Stand in the center."],
    ["Chair", "Sit on the chair."],
    ["Champion", "He is the chess champion."],
    ["Change", "Change your clothes."],
    ["Chat", "They had a friendly chat."],
    ["Cheat", "It's wrong to cheat on a test."],
    ["Check", "Check your work before submitting it."],
    ["Cheer", "Let's cheer for them."],
    ["Cheese", "Cheese is tasty."],
    ["Cheetah", "A cheetah runs fast."],
    ["Chest", "He has a broad chest."],
    ["Chew", "Chew your food well."],
    ["Chicken", "The chicken clucked."],
    ["Child", "The child is playing."],
    ["Chimney", "Smoke came out of the chimney."],
    ["Chin", "He touched his chin."],
    ["Chip", "I ate a chip."],
    ["Chocolate", "Chocolate is sweet."],
    ["Chop", "Chop the vegetables."],
    ["Chore", "Doing the dishes is my least favorite chore."],
    ["Circle", "Draw a big circle."],
    ["Citizen", "He is a good citizen."],
    ["Classroom", "The classroom is quiet."],
    ["Claw", "The cat has a sharp claw."],
    ["Clean", "She likes to clean her room."],
    ["Clear", "The sky is clear."],
    ["Cliff", "The cliff is high."],
    ["Climb", "Climb the tree carefully."],
    ["Clock", "The clock shows the time."],
    ["Cloudy", "The sky was cloudy."],
    ["Club", "Join the book club."],
    ["Cold", "It is very cold outside."],
    ["Collect", "Collect all the toys."],
    ["Color", "My favorite color is blue."],
    ["Column", "The article was in the first column."],
    ["Comfort", "The blanket provided comfort on a cold night."],
    ["Community", "The community came together to help."],
    ["Compare", "Compare the two prices."],
    ["Compass", "The compass points north."],
    ["Complain", "He complained about the noise."],
    ["Connect", "Connect the dots."],
    ["Construct", "They will construct a new building."],
    ["Cool", "The breeze is cool."],
    ["Core", "The core is strong."],
    ["Corner", "Turn the corner."],
    ["Costume", "She wore a witch costume."],
    ["Could", "Could you help me?"],
    ["Count", "Can you count to ten?"],
    ["Country", "They live in the country."],
    ["Course", "He took a course in photography."],
    ["Cozy", "The blanket is cozy."],
    ["Crab", "The crab walks sideways."],
    ["Crash", "The car crash was loud."],
    ["Crazy", "That idea is crazy."],
    ["Cricket", "Cricket is a fun sport."],
    ["Crowd", "The crowd cheered."],
    ["Crowded", "The bus was crowded."],
    ["Cry", "The baby will cry."],
    ["Cue", "That was my cue to leave."],
    ["Cup", "Fill the cup."],
    ["Curious", "The cat is curious."],
    ["Curved", "The road curved to the left."],
    ["Dad", "My dad is funny."],
    ["Daily", "She reads the daily news."],
    ["Damp", "The towel is damp."],
    ["Danger", "Beware of danger."],
    ["Dangerous", "The road is dangerous."],
    ["Dash", "He made a quick dash."],
    ["Daughter", "She loves her daughter."],
    ["Dawn", "We woke up at dawn."],
    ["Day", "Today is a good day."],
    ["December", "December is cold."],
    ["Decide", "He will decide soon."],
    ["Decision", "It was a tough decision to make."],
    ["Decorate", "They decorated the room."],
    ["Deep", "The lake is very deep."],
    ["Deer", "A deer ran across the field."],
    ["Delicious", "The cake was delicious."],
    ["Demolish", "They will demolish the building."],
    ["Denied", "He denied the accusation."],
    ["Dentist", "I have an appointment with the dentist."],
    ["Deserve", "You deserve a reward."],
    ["Design", "She likes to design clothes."],
    ["Did", "I did my homework."],
    ["Different", "They have different tastes."],
    ["Digit", "A digit is a number."],
    ["Dime", "A dime is small."],
    ["Dinner", "We had dinner early."],
    ["Disappear", "The magician made the rabbit disappear."],
    ["Discard", "Discard the old papers."],
    ["Disconnect", "Disconnect the cable."],
    ["Discover", "They discover new things."],
    ["Dish", "She washed the dish."],
    ["Dive", "He can dive well."],
    ["Divide", "Let's divide the cake."],
    ["Division", "Division is a math operation."],
    ["Dodge", "He dodged the ball."],
    ["Does", "Does she know?"],
    ["Doll", "The doll is pretty."],
    ["Dollar", "He found a dollar."],
    ["Dolphin", "The dolphin is playful."],
    ["Done", "I am done with my work."],
    ["Doubt", "I doubt it will rain."],
    ["Down", "Sit down."],
    ["Doze", "She likes to doze off."],
    ["Dozen", "I bought a dozen eggs."],
    ["Drawer", "She opened the drawer to find a pen."],
    ["Dress", "She wore a red dress."],
    ["Drink", "Drink some juice."],
    ["Drip", "Water began to drip from the tap."],
    ["Drive", "He can drive a car."],
    ["Drop", "Don't drop the glass."],
    ["Drowsy", "She felt drowsy after lunch."],
    ["Drum", "He plays the drum."],
    ["Dryer", "The clothes are in the dryer."],
    ["Duck", "The duck swims."],
    ["Each", "Each student has a book."],
    ["Eagle", "The eagle soared high."],
    ["Ear", "My ear hurts."],
    ["Early", "She woke up early today."],
    ["Earn", "He wants to earn money."],
    ["Earth", "The earth is round."],
    ["Easier", "This task is easier."],
    ["Easy", "This game is easy."],
    ["Eat", "Eat your vegetables."],
    ["Edge", "She stood at the edge."],
    ["Egg", "I ate a boiled egg."],
    ["Eight", "There are eight apples in the basket."],
    ["Elbow", "Bend your elbow."],
    ["Elephant", "The elephant is huge."],
    ["Eleven", "The clock struck eleven."],
    ["End", "This is the end."],
    ["Enemy", "He faced his enemy."],
    ["Enormous", "The elephant is enormous."],
    ["Environment", "Protect the environment."],
    ["Equal", "Both sides are equal."],
    ["Even", "Even he agreed."],
    ["Evening", "Good evening, everyone."],
    ["Every", "Every child loves to play."],
    ["Everyone", "Everyone is here."],
    ["Everywhere", "They searched everywhere."],
    ["Exactly", "That's exactly right."],
    ["Example", "This is an example."],
    ["Excess", "Avoid excess sugar."],
    ["Excitement", "The excitement was high."],
    ["Exercise", "Exercise is important."],
    ["Exhausted", "He was exhausted after the run."],
    ["Expensive", "The watch is expensive."],
    ["Extinct", "Dinosaurs are extinct."],
    ["Eye", "She has a blue eye."],
    ["Fabric", "The fabric is soft."],
    ["Fabulous", "She had a fabulous time."],
    ["Face", "She has a kind face."],
    ["Fact", "It's a fact."],
    ["Factory", "He works in a factory."],
    ["Fair", "The game was fair."],
    ["Fall", "Be careful not to fall."],
    ["Family", "My family is very supportive."],
    ["Famous", "She is a famous singer."],
    ["Fancy", "She wore a fancy dress to the party."],
    ["Farmer", "The farmer planted corn in the field."],
    ["Fast", "He runs very fast."],
    ["Fasten", "Please fasten your seatbelt."],
    ["Father", "My father is tall."],
    ["Fear", "He faced his fear of heights."],
    ["Feast", "We had a feast."],
    ["Feather", "The feather is light."],
    ["February", "February is short."],
    ["Fed", "I fed the dog."],
    ["Feed", "Please feed the fish."],
    ["Feet", "My feet are tired."],
    ["Few", "I have a few friends."],
    ["Field", "The field is green."],
    ["Fight", "They had a small fight."],
    ["Final", "This is the final round."],
    ["Finally", "We finally arrived."],
    ["Find", "Find the hidden object."],
    ["Fingers", "Count your fingers."],
    ["Firefly", "A firefly glowed in the dark."],
    ["First", "She was the first to arrive."],
    ["Fish", "The fish swims."],
    ["Five", "Five fingers on a hand."],
    ["Fix", "He can fix the broken chair."],
    ["Flap", "The bird's wings flap."],
    ["Flat", "The road ahead is flat and smooth."],
    ["Flee", "They had to flee from the danger."],
    ["Flip", "She did a flip on the trampoline."],
    ["Float", "The boat can float."],
    ["Flood", "The flood was severe."],
    ["Floor", "The floor is clean."],
    ["Flutter", "The butterfly fluttered."],
    ["Fog", "The fog made it hard to see."],
    ["Fold", "Fold the paper neatly."],
    ["Foot", "My foot is cold."],
    ["Football", "He plays football."],
    ["Footprint", "He left a footprint in the sand."],
    ["For", "This is for you."],
    ["Force", "Use gentle force."],
    ["Forest", "The forest is dense."],
    ["Found", "I found my keys."],
    ["Four", "Four legs on a chair."],
    ["Fox", "The fox is clever."],
    ["Fraction", "A fraction of the cake."],
    ["Freezing", "It was freezing outside last night."],
    ["Fresh", "The bread is fresh."],
    ["Friday", "Friday is a fun day."],
    ["Friend", "She is my best friend."],
    ["Frighten", "The noise can frighten."],
    ["Frog", "The frog jumps."],
    ["Fuel", "The car needs fuel."],
    ["Funny", "The joke is funny."],
    ["Gap", "There is a gap."],
    ["Gather", "Let's gather around the campfire."],
    ["Gathered", "They gathered together."],
    ["Gave", "She gave me a gift."],
    ["Gaze", "She gazed at the stars."],
    ["Gentle", "The kitten was very gentle."],
    ["Get", "Get the ball."],
    ["Giant", "The giant towered over the village."],
    ["Gift", "He gave a gift."],
    ["Giggle", "The baby let out a giggle."],
    ["Giraffe", "The giraffe is tall."],
    ["Girl", "The girl is smiling."],
    ["Give", "Please give me the pen."],
    ["Glad", "I am glad you came."],
    ["Glance", "She took a quick glance at the clock."],
    ["Glass", "The glass is full."],
    ["Glide", "The bird can glide."],
    ["Glossy", "The magazine had glossy pages."],
    ["Glow", "The lamp gave a soft glow."],
    ["Goal", "His goal is to run a marathon."],
    ["Goat", "The goat is eating grass."],
    ["Goes", "She goes to school daily."],
    ["Going", "We are going home."],
    ["Gold", "The ring is gold."],
    ["Good", "This is good news."],
    ["Governor", "The governor spoke."],
    ["Grab", "She tried to grab the last cookie."],
    ["Grateful", "I am grateful for your help."],
    ["Gravity", "Gravity pulls us down."],
    ["Great", "You did a great job."],
    ["Greedy", "Don't be greedy."],
    ["Green", "The grass is green."],
    ["Group", "They formed a study group."],
    ["Grow", "Plants grow with sunlight."],
    ["Gym", "I go to the gym daily."],
    ["Habitat", "Animals need a habitat."],
    ["Had", "I had a toy."],
    ["Hair", "She has long hair."],
    ["Half", "She ate half of the sandwich."],
    ["Hamburgers", "Hamburgers are tasty."],
    ["Hamster", "The hamster is cute."],
    ["Hand", "Shake my hand."],
    ["Happen", "What will happen next?"],
    ["Happy", "She feels happy today."],
    ["Hard", "The test was hard."],
    ["Harm", "He means no harm."],
    ["Has", "She has a cat."],
    ["Hatch", "The chicks will hatch soon."],
    ["Health", "Exercise is good for your health."],
    ["Heap", "There was a heap of clothes on the floor."],
    ["Hear", "I can hear the birds."],
    ["Heard", "I heard a noise."],
    ["Heavy", "The box is heavy."],
    ["Held", "She held the baby gently."],
    ["Help", "Can you help me with this?"],
    ["Hen", "The hen lays eggs."],
    ["Her", "Her dress is blue."],
    ["Herd", "A herd of cows."],
    ["Here", "Come here."],
    ["Hide", "He decided to hide behind the tree."],
    ["High", "The mountain is high."],
    ["Higher", "The balloon floated higher and higher."],
    ["Him", "I saw him yesterday."],
    ["Hint", "Give me a hint."],
    ["His", "This is his hat."],
    ["Hobby", "Painting is her favorite hobby."],
    ["Hold", "Hold my hand."],
    ["Holiday", "They went on holiday."],
    ["Home", "I am going home."],
    ["Honest", "He is always honest with his friends."],
    ["Honey", "Honey is sweet."],
    ["Horse", "The horse runs fast."],
    ["Hospital", "She works at the hospital."],
    ["Hot", "The soup is hot."],
    ["Hour", "It took an hour."],
    ["House", "Their house is big."],
    ["Howl", "The wolves began to howl at the moon."],
    ["Hungry", "I am very hungry."],
    ["Idea", "That's a good idea."],
    ["Ideal", "This is the ideal place."],
    ["If", "If it rains, we stay inside."],
    ["Important", "This is an important meeting."],
    ["Impossible", "Nothing is impossible with effort."],
    ["Include", "Please include your phone number."],
    ["Increase", "We need to increase our efforts."],
    ["Injure", "Don't injure yourself."],
    ["Injury", "She had a minor injury from the fall."],
    ["Input", "Please input your name."],
    ["Insect", "The insect is tiny."],
    ["Instrument", "She plays an instrument."],
    ["Interest", "I have an interest in art."],
    ["Into", "Go into the house."],
    ["Invent", "He likes to invent things."],
    ["Invitation", "I received an invitation to the party."],
    ["Invite", "Invite your friends over."],
    ["Is", "She is happy."],
    ["Island", "The island is beautiful."],
    ["It", "It is raining."],
    ["Its", "Its color is bright."],
    ["January", "January is cold."],
    ["Jealous", "He felt jealous of his friend's new bike."],
    ["Jelly", "Jelly is wobbly."],
    ["Jiggle", "The jelly will jiggle."],
    ["July", "July is hot."],
    ["Jumble", "The words were a jumble."],
    ["Jump", "Jump over the puddle."],
    ["Jump", "He can jump high."],
    ["June", "June is sunny."],
    ["Jungle", "The jungle is dense."],
    ["Just", "I just finished my work."],
    ["Keep", "Keep your room clean."],
    ["Kept", "She kept the book."],
    ["Kettle", "The kettle whistled."],
    ["Keyboard", "The keyboard is dusty."],
    ["Kick", "Kick the ball."],
    ["Kind", "She is very kind."],
    ["Kiss", "She gave him a kiss."],
    ["Kitchen", "The kitchen is clean."],
    ["Knee", "Bend your knee."],
    ["Knight", "The knight wore shining armor."],
    ["Knock", "Knock on the door."],
    ["Know", "I know the answer."],
    ["Label", "Label the jars."],
    ["Large", "The room is large."],
    ["Last", "I was the last one."],
    ["Late", "I am late."],
    ["Laugh", "They laugh a lot."],
    ["Laundry", "She did the laundry on Sunday."],
    ["Leader", "The leader gave a speech."],
    ["Leap", "The frog took a leap."],
    ["Learn", "I love to learn."],
    ["Leave", "I will leave soon."],
    ["Left", "She left early."],
    ["Leg", "My leg is strong."],
    ["Leg", "Stretch your leg."],
    ["Lemonade", "Lemonade is refreshing."],
    ["Lend", "Can you lend me a book?"],
    ["Leopard", "The leopard is fast."],
    ["Less than", "Five is less than ten."],
    ["Let", "Let me help."],
    ["Letter", "I wrote a letter."],
    ["Library", "The library is quiet."],
    ["Light", "The light is bright."],
    ["Like", "I like ice cream."],
    ["Lime", "Lime adds a tangy flavor."],
    ["Limit", "Know your limit."],
    ["Line", "Draw a straight line."],
    ["Lion", "The lion roared loudly."],
    ["Lips", "Her lips are red."],
    ["List", "Make a shopping list."],
    ["Listen", "Listen to the music."],
    ["Little", "The puppy is little."],
    ["Little", "The little bird sang."],
    ["Live", "We live in Kansas."],
    ["Lively", "The market was lively and bustling."],
    ["Lizard", "A lizard crawled on the wall."],
    ["Lizard", "The lizard is small."],
    ["Local", "We visited the local market."],
    ["Lock", "Lock the door."],
    ["Lonely", "She felt lonely at home."],
    ["Lonely", "He felt lonely."],
    ["Long", "The road is long."],
    ["Long", "The road is very long."],
    ["Look", "Look at the sky."],
    ["Loosen", "Loosen the knot to untie it."],
    ["Lot", "She has a lot of books."],
    ["Loud", "The music is too loud."],
    ["Lovely", "The garden is lovely."],
    ["Lunch", "Lunch is ready."],
    ["Luxury", "They stayed in a luxury hotel."],
    ["Macaroni", "Macaroni is delicious."],
    ["Mad", "He was mad at me."],
    ["Made", "She made a cake."],
    ["Magnet", "The magnet is strong."],
    ["Make", "Make a wish."],
    ["Mammal", "A whale is a mammal."],
    ["Mansion", "The mansion is huge."],
    ["Many", "I have many toys."],
    ["March", "March is windy."],
    ["Mask", "Wear a mask."],
    ["Mask", "She wore a mask to the party."],
    ["Match", "The socks match perfectly."],
    ["Matter", "It doesn't matter."],
    ["May", "May is warm."],
    ["Meat", "He likes meat."],
    ["Medium", "The shirt is medium size."],
    ["Meet", "Let's meet tomorrow."],
    ["Melt", "The ice will melt in the sun."],
    ["Mention", "He didn't mention the problem."],
    ["Mess", "The room is a mess."],
    ["Might", "It might rain today."],
    ["Minus", "Ten minus five is five."],
    ["Minute", "Wait a minute."],
    ["Misty", "The morning was misty and cool."],
    ["Mix", "Mix the batter."],
    ["Modern", "They live in a modern apartment."],
    ["Mom", "My mom is great."],
    ["Monday", "Monday is the first day of the week."],
    ["Money", "He saved his money."],
    ["Month", "This month is busy."],
    ["Monthly", "The magazine is published monthly."],
    ["More", "I want more."],
    ["More than", "She has more than enough."],
    ["Morning", "Good morning, sunshine."],
    ["Most", "She is the most talented."],
    ["Mother", "Her mother is kind."],
    ["Motion", "The motion was smooth."],
    ["Motor", "The motor started smoothly."],
    ["Mountain", "We climbed the tall mountain."],
    ["Mouse", "The mouse ran away."],
    ["Move", "Move the chair."],
    ["Much", "Thank you very much."],
    ["Multiplication", "Multiplication is easy."],
    ["Museum", "They visited the art museum."],
    ["Must", "You must try this."],
    ["Nail", "I broke a nail."],
    ["Nap", "He took a short nap."],
    ["Napkin", "Use a napkin."],
    ["Narrow", "The path was narrow and winding."],
    ["Near", "The park is near my house."],
    ["Nearby", "The store is nearby."],
    ["Nearly", "It's nearly done."],
    ["Neck", "My neck is sore."],
    ["Nervous", "He felt nervous."],
    ["Net", "The fisherman cast his net."],
    ["Never", "Never give up."],
    ["New", "I bought a new phone."],
    ["Next", "You are next in line."],
    ["Nibble", "The mouse nibbled on cheese."],
    ["Nice", "She is nice."],
    ["Nickel", "A nickel is small."],
    ["Night", "The stars shine at night."],
    ["Nine", "There are nine books on the shelf."],
    ["Noisy", "The room was noisy."],
    ["None", "None of them are here."],
    ["Normal", "Everything is normal."],
    ["Nose", "He has a big nose."],
    ["Note", "He left a note on the fridge."],
    ["Notebook", "Write in your notebook."],
    ["Nothing", "I have nothing to say."],
    ["Notice", "I didn't notice the sign."],
    ["November", "November is chilly."],
    ["Now", "Do it now."],
    ["Now", "Let's go now."],
    ["Number", "Pick a number."],
    ["Obey", "Dogs should obey their owners."],
    ["Object", "The object is shiny."],
    ["Ocean", "The ocean is vast."],
    ["Ocean", "The ocean waves were calm."],
    ["October", "October is spooky."],
    ["Octopus", "The octopus has eight arms."],
    ["Odd", "That's odd."],
    ["Odor", "The odor was strong."],
    ["Off", "Turn off the light."],
    ["Often", "I often read books."],
    ["Old", "This book is old."],
    ["Once", "Once upon a time."],
    ["One", "One is the first number."],
    ["Only", "She is the only child."],
    ["Open", "Open the door."],
    ["Opposite", "The opposite of hot is cold."],
    ["Or", "Do you want tea or coffee?"],
    ["Orange", "The fruit is orange."],
    ["Order", "Place your order."],
    ["Other", "The other side."],
    ["Our", "This is our house."],
    ["Out", "Let's go out."],
    ["Output", "The output is correct."],
    ["Owl", "The owl hooted."],
    ["Pace", "She walked at a fast pace."],
    ["Pack", "He packed his bag for the trip."],
    ["Package", "The package arrived."],
    ["Pad", "Write on the pad."],
    ["Page", "Turn the page."],
    ["Pain", "He felt a sharp pain in his leg."],
    ["Paint", "Paint the wall blue."],
    ["Pair", "I bought a new pair of shoes."],
    ["Pale", "She looked pale."],
    ["Pan", "The pan is hot."],
    ["Panda", "The panda is cute."],
    ["Pants", "His pants were too short."],
    ["Paper", "Write on the paper."],
    ["Parade", "The parade was colorful."],
    ["Part", "This is part of the puzzle."],
    ["Passenger", "The passenger sat quietly in the car."],
    ["Past", "We learned about the past."],
    ["Path", "Follow the path."],
    ["Patient", "The doctor was very patient."],
    ["Pattern", "The dress had a beautiful pattern."],
    ["Patterns", "The patterns are pretty."],
    ["Peaceful", "The lake was peaceful at dawn."],
    ["Peach", "The peach is juicy."],
    ["Peak", "They reached the mountain peak."],
    ["Peanut", "He ate a peanut."],
    ["Pear", "The pear is ripe."],
    ["Peck", "The bird gave a quick peck."],
    ["Pen", "I need a pen."],
    ["Pencil", "Sharpen your pencil."],
    ["Penny", "A penny saved."],
    ["Perfect", "The day was perfect."],
    ["Person", "She is a nice person."],
    ["Pest", "The garden was full of pests."],
    ["Pickle", "I love pickles."],
    ["Picture", "She drew a picture."],
    ["Pig", "The pig is pink."],
    ["Pilot", "The pilot flew the plane smoothly."],
    ["Pink", "The dress is pink."],
    ["Place", "This place is beautiful."],
    ["Plain", "The dress is plain."],
    ["Plan", "We need a plan for the trip."],
    ["Planet", "Earth is a planet."],
    ["Play", "The children love to play outside."],
    ["Playground", "The playground is fun."],
    ["Pleasant", "The weather is pleasant."],
    ["Plus", "Two plus two equals four."],
    ["Point", "Point to the sky."],
    ["Police", "The police are here."],
    ["Polish", "She needs to polish her shoes."],
    ["Polite", "He is always polite to others."],
    ["Pond", "Ducks swam in the pond."],
    ["Pool", "They swam in the pool."],
    ["Popcorn", "Popcorn is a great snack."],
    ["Pot", "The pot is boiling."],
    ["Potato", "He likes potato chips."],
    ["Present", "She gave him a present."],
    ["President", "The president gave a speech."],
    ["Pretend", "The children like to pretend they are pirates."],
    ["Problem", "Solve the problem."],
    ["Promise", "I promise to call you later."],
    ["Proof", "We need proof of purchase."],
    ["Protect", "A helmet can protect your head."],
    ["Proud", "She felt proud of her work."],
    ["Pudding", "The pudding is delicious."],
    ["Pull", "Pull the door open."],
    ["Purple", "The flower is purple."],
    ["Purpose", "What's the purpose?"],
    ["Push", "Push the door open."],
    ["Put", "Put it on the table."],
    ["Quack", "The duck says quack."],
    ["Quarter", "A quarter is 25 cents."],
    ["Queen", "The queen waved."],
    ["Question", "Ask a question."],
    ["Quickly", "Finish quickly."],
    ["Quietly", "Speak quietly."],
    ["Quiz", "We have a quiz today."],
    ["Rabbit", "The rabbit is cute."],
    ["Raccoon", "The raccoon is sneaky."],
    ["Radio", "Turn on the radio."],
    ["Rain", "It started to rain."],
    ["Rainy", "It was a rainy afternoon."],
    ["Raise", "Raise your hand."],
    ["Rapid", "The river's current was rapid."],
    ["Reach", "He tried to reach the top shelf."],
    ["Read", "I read a story."],
    ["Real", "Is this real?"],
    ["Rectangle", "The table is a rectangle."],
    ["Red", "The apple is red."],
    ["Reflect", "The mirror reflects light."],
    ["Relax", "She likes to relax with a book."],
    ["Remove", "Please remove your shoes at the door."],
    ["Repeat", "Can you repeat the question?"],
    ["Rescue", "The firefighters came to rescue the cat."],
    ["Rest", "Take some rest."],
    ["Restart", "We had to restart the computer."],
    ["Return", "She will return the book tomorrow."],
    ["Ride", "Ride your bike safely."],
    ["Right", "Turn right at the corner."],
    ["Ripe", "The apples are ripe and ready to eat."],
    ["Rise", "The sun will rise soon."],
    ["Road", "The road is long."],
    ["Roar", "The lion let out a loud roar."],
    ["Rock", "He threw a rock."],
    ["Roof", "The roof is leaking."],
    ["Room", "The room is tidy."],
    ["Rotten", "The apple was rotten inside."],
    ["Rough", "The surface was rough to the touch."],
    ["Round", "The ball is round."],
    ["Rounding", "Rounding numbers is easy."],
    ["Row", "They sat in the front row."],
    ["Rule", "Follow the rule."],
    ["Rumor", "A rumor spread quickly."],
    ["Run", "He likes to run every morning."],
    ["Rusty", "The old bike was rusty."],
    ["Sad", "She felt sad today."],
    ["Safe", "The children are safe."],
    ["Said", "She said hello."],
    ["Salmon", "Salmon is tasty."],
    ["Same", "We have the same shoes."],
    ["Saturday", "Saturday is for relaxing."],
    ["Saw", "I saw a movie."],
    ["Say", "What did you say?"],
    ["Scene", "The movie's final scene was intense."],
    ["Scholar", "She is a scholar."],
    ["Scratch", "The cat left a scratch on the couch."],
    ["Scrub", "She had to scrub the dirty pan."],
    ["Sea", "The sea is vast."],
    ["Seal", "The seal clapped."],
    ["Search", "They searched for clues."],
    ["Second", "Just a second, please."],
    ["Seed", "Plant the seed in the soil."],
    ["Seem", "You seem happy."],
    ["Selfish", "It's selfish to keep everything for yourself."],
    ["Send", "Please send me the file."],
    ["Sentence", "Write a sentence."],
    ["September", "September is cool."],
    ["Serious", "He looked very serious during the meeting."],
    ["Settle", "They settled in a new town."],
    ["Seven", "There are seven days in a week."],
    ["Shade", "They sat in the shade of a tree."],
    ["Shaky", "His hands were shaky from nerves."],
    ["Shape", "The shape is round."],
    ["Share", "She shared her toys."],
    ["Sheep", "The sheep is fluffy."],
    ["Shell", "She found a beautiful shell on the beach."],
    ["Shelter", "They found shelter from the rain."],
    ["Ship", "The ship sailed across the ocean."],
    ["Shiver", "He began to shiver."],
    ["Shoot", "He can shoot a basketball well."],
    ["Shoulder", "My shoulder hurts."],
    ["Shovel", "Use the shovel to dig a hole."],
    ["Shy", "The shy girl hid behind her mom."],
    ["Sibling", "She has one sibling, a younger brother."],
    ["Sight", "The sight was breathtaking."],
    ["Signal", "Give me a signal."],
    ["Silent", "The room was completely silent."],
    ["Silk", "She wore a silk dress."],
    ["Silly", "That was a silly joke."],
    ["Similar", "These two books are similar."],
    ["Simple", "The instructions were simple to follow."],
    ["Since", "I have known her since kindergarten."],
    ["Sing", "She loves to sing."],
    ["Sink", "The dishes are in the sink."],
    ["Sister", "My sister is kind."],
    ["Sit", "Sit down, please."],
    ["Six", "Six sides on a cube."],
    ["Size", "What is your shoe size?"],
    ["Skill", "He has a special skill."],
    ["Sky", "The sky is blue."],
    ["Slept", "He slept through the storm."],
    ["Slide", "The children love to slide."],
    ["Slight", "There was a slight delay."],
    ["Slip", "Be careful not to slip."],
    ["Slippery", "The floor was slippery when wet."],
    ["Slow", "The turtle is slow."],
    ["Small", "The puppy is small."],
    ["Smell", "The flowers smell nice."],
    ["Smile", "She gave a big smile."],
    ["Smooth", "The surface is smooth."],
    ["Snack", "Time for a snack."],
    ["Snack", "I had a snack."],
    ["Snake", "The snake slithers."],
    ["Sneaky", "The cat was sneaky and stole the fish."],
    ["Sniff", "She gave the flower a sniff."],
    ["Snuggle", "They snuggle on the couch."],
    ["Soap", "She washed her hands with soap."],
    ["Sob", "She began to sob quietly."],
    ["Soccer", "Soccer is a popular sport."],
    ["Soda", "I like soda."],
    ["Soft", "The pillow is soft."],
    ["Soil", "Plants grow in soil."],
    ["Someone", "Someone left their bag here."],
    ["Son", "He loves his son."],
    ["Song", "She sang a beautiful song."],
    ["Soon", "I will see you soon."],
    ["Sort", "Sort the papers by date."],
    ["Sound", "The sound is loud."],
    ["Soup", "The soup is hot."],
    ["Space", "Space is vast."],
    ["Spaghetti", "Spaghetti is delicious."],
    ["Sparkle", "The stars sparkle at night."],
    ["Special", "Today is a special day."],
    ["Speed", "He drove at high speed."],
    ["Spiral", "The staircase spiraled upwards."],
    ["Splash", "The splash was loud."],
    ["Splendid", "The view from the top was splendid."],
    ["Spring", "Flowers bloom in the spring."],
    ["Sprint", "He sprinted to the finish."],
    ["Square", "The box is square-shaped."],
    ["Squash", "He likes to squash bugs."],
    ["Stack", "He stacked the books neatly."],
    ["Stamp", "Put a stamp on the letter."],
    ["Stand", "Please stand in line."],
    ["State", "The state is large."],
    ["Steady", "Keep a steady pace."],
    ["Steep", "The hill was very steep."],
    ["Still", "The water is still."],
    ["Stomach", "My stomach is full."],
    ["Stone", "He threw a stone."],
    ["Stop", "Stop the car."],
    ["Storm", "A storm is coming tonight."],
    ["Stove", "The stove is on."],
    ["Strap", "The strap broke suddenly."],
    ["Straw", "The straw is dry."],
    ["Stream", "The stream flowed gently."],
    ["Street", "Cross the street carefully."],
    ["Stretch", "Remember to stretch before exercising."],
    ["Stripe", "The zebra has black and white stripes."],
    ["Study", "I need to study."],
    ["Stuff", "Stuff the bag with clothes."],
    ["Subtract", "Subtract the smaller number."],
    ["Subtraction", "Subtraction is simple."],
    ["Such", "Such a lovely day!"],
    ["Suddenly", "He suddenly appeared."],
    ["Summer", "Summer is my favorite season."],
    ["Sunday", "Sunday is a rest day."],
    ["Sunny", "It was a sunny day."],
    ["Supplies", "We need more art supplies."],
    ["Support", "They offered support to the team."],
    ["Surround", "Trees surround the small cabin."],
    ["Swim", "I can swim fast."],
    ["Switch", "Flip the switch to turn on the light."],
    ["Symmetry", "The design has symmetry."],
    ["Take", "Take a seat."],
    ["Tangerine", "Tangerines are sweet."],
    ["Tangled", "The yarn was tangled."],
    ["Tasty", "The cake is tasty."],
    ["Tea", "I like tea."],
    ["Team", "Our team won the game."],
    ["Teeth", "Brush your teeth daily."],
    ["Telephone", "Answer the telephone."],
    ["Telescope", "He looked through the telescope."],
    ["Tell", "Tell me the truth."],
    ["Ten", "I have ten apples."],
    ["Terrified", "She was terrified of the dark."],
    ["Than", "She is taller than me."],
    ["Thank", "Thank you!"],
    ["That", "I like that one."],
    ["Theater", "We went to the theater."],
    ["Their", "Their house is big."],
    ["Them", "I saw them at the park."],
    ["Then", "First we eat, then we play."],
    ["There", "There is a cat on the roof."],
    ["These", "These are my books."],
    ["Thick", "The book is thick."],
    ["Thigh", "My thigh is sore."],
    ["Thin", "The paper is thin."],
    ["Thing", "This thing is heavy."],
    ["Think", "I think it's a good idea."],
    ["Third", "She finished in third place."],
    ["Though", "He is smart, though."],
    ["Thought", "I had a thought."],
    ["Three", "Three is a crowd."],
    ["Thumb", "Thumbs up!"],
    ["Thunder", "The thunder was loud and scary."],
    ["Thursday", "Thursday is almost the weekend."],
    ["Ticket", "He bought a ticket for the concert."],
    ["Tiger", "The tiger roars."],
    ["Time", "Time flies."],
    ["Tiny", "The ant is tiny."],
    ["Together", "We work together."],
    ["Tomorrow", "We will go hiking tomorrow."],
    ["Ton", "This weighs a ton."],
    ["Too", "It is too hot today."],
    ["Tooth", "My tooth is loose."],
    ["Top", "She is on top."],
    ["Touch", "The baby reached out to touch the toy."],
    ["Tough", "The exam was tough."],
    ["Tour", "They went on a tour."],
    ["Towel", "She dried her hands with a towel."],
    ["Tower", "The tower is very tall."],
    ["Toy", "The toy is fun."],
    ["Trade", "They trade goods."],
    ["Transportation", "Buses are a common form of transportation."],
    ["Travel", "They love to travel."],
    ["Tray", "She carried the tray."],
    ["Treat", "She gave me a treat."],
    ["Triangle", "A triangle has three sides."],
    ["Trick", "He played a trick on me."],
    ["Trip", "We went on a trip."],
    ["Trunk", "The car's trunk was full."],
    ["Trust", "I trust you completely."],
    ["Truth", "Always tell the truth."],
    ["Tuesday", "Tuesday comes after Monday."],
    ["Tune", "The tune is catchy."],
    ["Turkey", "The turkey is big."],
    ["Turn", "Turn the page."],
    ["Twelve", "There are twelve months in a year."],
    ["Two", "Two comes after one."],
    ["Under", "The cat is under the table."],
    ["Unicorn", "A unicorn is magical."],
    ["Universe", "The universe is vast."],
    ["Unlock", "Unlock the door."],
    ["Upon", "Once upon a time."],
    ["Upset", "She was upset about the news."],
    ["Us", "They invited us to the party."],
    ["Use", "Can I use your pen?"],
    ["Useless", "This tool is useless."],
    ["Usually", "I usually wake up early."],
    ["Vacation", "We are going on vacation."],
    ["Vanilla", "Vanilla ice cream is yummy."],
    ["Very", "I am very happy."],
    ["Veteran", "He is a war veteran."],
    ["Video", "Watch the video."],
    ["Village", "The village is peaceful."],
    ["Visit", "They visit often."],
    ["Voice", "Her voice is soft."],
    ["Waffles", "Waffles are for breakfast."],
    ["Walk", "Let's walk to school."],
    ["Want", "I want ice cream."],
    ["Warm", "The blanket is warm and cozy."],
    ["Warn", "He warned us about the storm."],
    ["Was", "She was here."],
    ["Wash", "Wash your hands."],
    ["Wash", "She will wash the dishes."],
    ["Watch", "Watch the show."],
    ["Water", "Drink some water."],
    ["Wave", "She gave a friendly wave."],
    ["Weak", "He felt weak after the flu."],
    ["Wealthy", "The wealthy man donated money."],
    ["Wednesday", "Wednesday is midweek."],
    ["Weed", "We need to weed the garden."],
    ["Weekly", "They have a weekly meeting."],
    ["Well", "She sings very well."],
    ["Went", "He went home."],
    ["Were", "They were here."],
    ["Where", "Where are you going?"],
    ["Which", "Which one do you like?"],
    ["Whirl", "The leaves began to whirl in the wind."],
    ["Whisper", "She whispered a secret."],
    ["White", "The snow is white."],
    ["Who", "Who is that?"],
    ["Why", "Why are you late?"],
    ["Wicked", "The witch was wicked and cruel."],
    ["Wiggle", "The baby likes to wiggle."],
    ["Wing", "The bird has a broken wing."],
    ["Winter", "Winter is cold and snowy."],
    ["Wipe", "Wipe the table clean."],
    ["Wise", "The owl is wise."],
    ["Wish", "Make a wish on a star."],
    ["Wolf", "The wolf howls."],
    ["Wonder", "I wonder what will happen."],
    ["Wonderful", "The view is wonderful."],
    ["Work", "I have to work today."],
    ["World", "The world is big."],
    ["Worry", "Don't worry about it."],
    ["Would", "Would you like some tea?"],
    ["Write", "Please write your name."],
    ["Wrong", "The answer is wrong."],
    ["Yard", "The kids played in the yard."],
    ["Yawn", "She began to yawn."],
    ["Year", "This year has been great."],
    ["Yellow", "The sun is yellow."],
    ["Yesterday", "She called me yesterday."],
    ["Yet", "I haven't finished yet."],
    ["Young", "The puppy is young."],
    ["Your", "Your book is here."],
    ["Yourself", "Take care of yourself."],
    ["Zebra", "The zebra has stripes."],
    ["Zero", "Zero is nothing."],
    ["Zigzag", "The path zigzagged up the hill."],
    ["about", "Tell me about it."],
    ["after", "After school, we play."],
    ["again", "Let's try again."],
    ["against", "We are against it."],
    ["all", "All the kids are here."],
    ["also", "I also like reading."],
    ["and", "Tom and Jerry are friends."],
    ["another", "Give me another chance."],
    ["any", "Do you have any questions?"],
    ["around", "Look around you."],
    ["as", "As tall as a tree."],
    ["ask", "Ask me anything."],
    ["at", "Meet me at the park."],
    ["back", "I'll be back soon."],
    ["be", "Be kind to others."],
    ["because", "I am happy because it's sunny."],
    ["become", "He will become a doctor."],
    ["before", "Wash your hands before eating."],
    ["begin", "Let's begin the game."],
    ["between", "Sit between them."],
    ["both", "Both are correct."],
    ["but", "I like pizza, but not olives."],
    ["by", "Stand by the door."],
    ["call", "Call me later."],
    ["can", "Can you help me?"],
    ["child", "The child is playing."],
    ["come", "Come here, please."],
    ["consider", "Consider this option."],
    ["could", "Could you pass the salt?"],
    ["day", "Have a nice day."],
    ["develop", "Develop your skills."],
    ["do", "Do your homework."],
    ["down", "Sit down, please."],
    ["during", "During the day, I work."],
    ["each", "Each student gets a book."],
    ["end", "This is the end."],
    ["even", "Even he can do it."],
    ["feel", "I feel great."],
    ["few", "I have a few friends."],
    ["find", "Find the hidden treasure."],
    ["first", "First, wash your hands."],
    ["follow", "Follow me."],
    ["for", "This gift is for you."],
    ["form", "Fill out this form."],
    ["from", "She is from Canada."],
    ["general", "In general, it's good."],
    ["get", "Get well soon."],
    ["give", "Give me a hand."],
    ["go", "Let's go home."],
    ["good", "Have a good day."],
    ["govern", "They govern the country."],
    ["great", "You did a great job."],
    ["hand", "Hold my hand."],
    ["have", "I have a dog."],
    ["he", "He is my brother."],
    ["head", "Use your head."],
    ["here", "Come here."],
    ["high", "The mountain is high."],
    ["hold", "Hold my hand."],
    ["home", "I am going home."],
    ["house", "This is my house."],
    ["how", "How are you?"],
    ["however", "However, I disagree."],
    ["if", "If it rains, we stay inside."],
    ["in", "The book is in the bag."],
    ["interest", "I have an interest in art."],
    ["into", "Go into the room."],
    ["it", "It is raining."],
    ["just", "Just do it."],
    ["know", "I know the answer."],
    ["large", "This is a large room."],
    ["last", "This is the last one."],
    ["late", "Don't be late."],
    ["lead", "Lead the way."],
    ["leave", "Don't leave me."],
    ["life", "Life is good."],
    ["like", "I like ice cream."],
    ["little", "I have a little dog."],
    ["long", "It's a long journey."],
    ["look", "Look at the stars."],
    ["make", "Make a wish."],
    ["man", "The man is tall."],
    ["many", "Many thanks."],
    ["may", "May I come in?"],
    ["mean", "What do you mean?"],
    ["might", "It might rain."],
    ["more", "I need more time."],
    ["most", "Most people agree."],
    ["move", "Move the chair."],
    ["much", "Thank you very much."],
    ["must", "You must try this."],
    ["nation", "Our nation is strong."],
    ["need", "I need help."],
    ["never", "Never give up."],
    ["new", "I have a new bike."],
    ["no", "No, thank you."],
    ["not", "Do not touch that."],
    ["now", "Let's go now."],
    ["number", "What's your number?"],
    ["of", "A cup of tea."],
    ["off", "Turn off the light."],
    ["old", "This book is old."],
    ["on", "The book is on the table."],
    ["one", "One apple a day."],
    ["only", "Only one left."],
    ["open", "Open the door."],
    ["or", "Tea or coffee?"],
    ["order", "Place your order."],
    ["other", "The other book is better."],
    ["out", "Let's go out."],
    ["over", "The game is over."],
    ["own", "I have my own room."],
    ["part", "This is part of the plan."],
    ["people", "People are friendly here."],
    ["person", "She is a nice person."],
    ["place", "This place is beautiful."],
    ["point", "What's the point?"],
    ["possible", "Anything is possible."],
    ["present", "I have a present for you."],
    ["problem", "We have a problem."],
    ["program", "I like this program."],
    ["public", "This is a public park."],
    ["real", "Is this real?"],
    ["right", "Turn right here."],
    ["same", "We have the same shoes."],
    ["say", "What did you say?"],
    ["school", "I go to school."],
    ["see", "I see a bird."],
    ["seem", "You seem tired."],
    ["set", "Set the table."],
    ["she", "She loves to dance."],
    ["should", "You should rest."],
    ["show", "Show me the way."],
    ["since", "I haven't seen you since then."],
    ["small", "This is a small box."],
    ["so", "I am so happy."],
    ["some", "I need some help."],
    ["state", "The state of Kansas."],
    ["still", "I still remember."],
    ["such", "Such a nice day."],
    ["system", "The system is down."],
    ["take", "Take a seat."],
    ["tell", "Tell me a story."],
    ["than", "She is taller than me."],
    ["that", "That is my car."],
    ["the", "The cat is sleeping."],
    ["then", "Then we went home."],
    ["there", "There is a cat on the roof."],
    ["these", "These are my shoes."],
    ["they", "They are playing outside."],
    ["thing", "This thing is heavy."],
    ["think", "I think so."],
    ["this", "This is my house."],
    ["those", "Those are my friends."],
    ["through", "Walk through the park."],
    ["time", "Time flies."],
    ["to", "I like to read."],
    ["too", "It's too hot."],
    ["turn", "Turn the page."],
    ["under", "The cat is under the table."],
    ["up", "Look up at the sky."],
    ["use", "Use a pencil."],
    ["very", "I am very happy."],
    ["want", "I want ice cream."],
    ["way", "Show me the way."],
    ["we", "We are going to the zoo."],
    ["well", "I am well, thank you."],
    ["what", "What is your name?"],
    ["when", "When is your birthday?"],
    ["where", "Where are you?"],
    ["which", "Which one is yours?"],
    ["while", "Wait here while I go."],
    ["who", "Who is that?"],
    ["will", "I will call you later."],
    ["with", "She is with her mom."],
    ["without", "I can't do it without you."],
    ["word", "What's the word?"],
    ["work", "I have work to do."],
    ["world", "The world is big."],
    ["would", "I would like some water."],
    ["year", "Happy New Year!"],
    ["you", "You are my friend."],
  ];
  return words;
}

export function getRandomSpellingWord() {
  const words = getWords();
  const index = getRandomInt(words.length - 1, 0);
  return words[index];
}

export function toNumber(input) {
  const numberMap = {
    O: 0,
    o: 0,
    a: 9,
    q: 9,
    b: 6,
    g: 9,
    s: 5,
    S: 5,
    z: 2,
    Z: 2,
    B: 8,
  };
  return numberMap[input] !== undefined ? numberMap[input] : input;
}

export function makeBalloonQuestion() {
  const n1 = getRandomInt(51, 10);
  const n2 = getRandomInt(n1 / 2, (-1 * n1) / 2);
  const n3 = -1 * getRandomInt(n1 + n2);

  function get2nd(num) {
    if (num === 0) return "";

    return num < 0
      ? `She gave away ${Math.abs(num)}.`
      : `She bought ${num} more.`;
  }
  return {
    context: `Nira bought ${n1} balloons. ${get2nd(n2)} ${
      n3 ? `${Math.abs(n3)} balloon popped.` : ""
    } How many balloons does Nira have left?`,
    result: n1 + n2 + n3,
  };
}

export function makeAppleQuestion() {
  const n1 = getRandomInt(51, 5);
  const n2 = -1 * getRandomInt(n1, 1);
  return {
    context: `Nick has ${n1} red apples and ${Math.abs(
      n2
    )} yellow apples. How many more red apples than yellow apples does he have?`,
    result: n1 + n2,
  };
}

export function makeBirdQuestion() {
  const n1 = getRandomInt(51, 5);
  const n2 = -1 * getRandomInt(n1, 1);
  return {
    context: `There are ${n1} birds on an apple tree. A squirrel chases ${Math.abs(
      n2
    )} away. How many birds are left on the tree?`,
    result: n1 + n2,
  };
}

export function makePencilQuestion() {
  const n1 = getRandomInt(20, 5);
  const n2 = getRandomInt(20, 2);
  const n3 = getRandomIntExcept(20, -1 * (n1 + n2), 0);
  function get3rd(num) {
    return num > 0
      ? `Moon gave him ${num} more.`
      : `He gave Moon ${Math.abs(num)}.`;
  }
  return {
    context: `Jamie has ${n1} pencils in his backpack and ${n2} pencils on the table.
              ${get3rd(n3)}
              How many pencils does he have altogether?`,
    result: n1 + n2 + n3,
  };
}

export function makeCandyQuestion() {
  const n1 = getRandomInt(30, 5);
  const n2 = getRandomIntExcept(n1, -1 * n1, 0);
  function getNext(num) {
    return num > 0
      ? `Luna has ${num} more than Moon`
      : `Luna has ${abs(num)} fewer candies than Moon`;
  }
  return {
    context: `Moon has ${n1} candies. ${getNext(
      n2
    )}. How many candies does Luna have?`,
    result: n1 + n2,
  };
}

export function makeOrangeQuestion() {
  const n1 = getRandomInt(30, 5);
  const n2 = -1 * getRandomInt(n1, 2);
  const n3 = getRandomInt(20, 5);
  return {
    context: `A basket contains ${n1} oranges. If ${abs(
      n2
    )} oranges are taken out and ${n3} more are added, how many oranges are in the basket now?`,
    result: n1 + n2 + n3,
  };
}

export function makeBookQuestion() {
  const n1 = getRandomInt(30, 5);
  const n2 = -1 * getRandomInt(n1, 2);
  const n3 = getRandomInt(20, 5);
  return {
    context: `Lisa has ${n1} books. She lends ${abs(
      n2
    )} books to her friend and then buys ${n3} more. How many books does Lisa have now?`,
    result: n1 + n2 + n3,
  };
}

export function makeMarbleQuestion() {
  const n1 = getRandomInt(30, 5);
  const n2 = -1 * getRandomInt(n1, 2);
  const n3 = getRandomInt(20, 5);
  return {
    context: `John has ${n1} marbles. He loses ${abs(
      n2
    )} marbles and then finds ${n3} more. How many marbles does John have now?`,
    result: n1 + n2 + n3,
  };
}

export function makeMoneyQuestion() {
  const n1 = getRandomInt(30, 5);
  const n2 = getRandomInt(20, 5);
  const n3 = -1 * getRandomInt(n1 + n2, 1);
  return {
    context: `Mike has ${n1} dollars. He earns ${n2} dollars from chores then spends ${abs(
      n3
    )} dollars on toys. How much money does Mike have now?`,
    result: n1 + n2 + n3,
  };
}

export function makeStudentQuestion() {
  const n1 = getRandomInt(50, 20);
  const n2 = -1 * getRandomInt(n1, 1);
  const n3 = getRandomInt(20, 2);
  return {
    context: `A classroom has ${n1} students. ${abs(
      n2
    )} students leave for a field trip, and ${n3} new students join the class. How many students are in the classroom now?`,
    result: n1 + n2 + n3,
  };
}

export function makeFliesQuestion() {
  const n1 = getRandomInt(50, 10);
  const n2 = -1 * getRandomInt(n1, 1);
  const n3 = getRandomInt(20, 2);
  return {
    context: `There are ${n1} flies on a pizza. Jame swat ${abs(
      n2
    )} with his hands. But the pizza is so good that ${n3} more flies come. How many flies are on the pizza?`,
    result: n1 + n2 + n3,
  };
}

export function makeCowQuestion() {
  const n1 = getRandomInt(50, 5);
  const n2 = getRandomIntExcept(n1, -1 * n1, 0);
  const n3 = getRandomIntExcept(n1 + n2, -1 * (n1 + n2), 0);
  function buySell(num) {
    return num > 0 ? `buys ${abs(num)}` : `sells ${abs(num)}`;
  }
  return {
    context: `A farmer has ${n1} cows. He ${buySell(
      n2
    )} cows in the morning and then ${buySell(n3)} ${
      n2 * n3 > 0 ? "more" : ""
    } in the afternoon. How many cows does the farmer have?`,
    result: n1 + n2 + n3,
  };
}

export function makeBananaQuestion() {
  const n1 = getRandomInt(50, 10);
  const n2 = getRandomInt(n1, -1 * n1);
  return {
    context: `A monkey has ${n1} bananas. A parrot has ${moreLessThan(
      n2
    )} the monkey. How many bananas does the parrot have?`,
    result: n1 + n2,
  };
}

export function makePirateQuestion() {
  const n1 = getRandomInt(50, 10);
  const n2 = getRandomInt(n1, -1 * n1);
  return {
    context: `A pirate has ${n1} gold coins. A sailor has ${moreLessThan(
      n2
    )}  the pirate. How many gold coins does the sailor have?`,
    result: n1 + n2,
  };
}

export function makeDragonQuestion() {
  const n1 = getRandomInt(50, 10);
  const n2 = getRandomInt(n1, -1 * n1);
  return {
    context: `A dragon has ${n1} jewels. A knight has ${moreLessThan(
      n2
    )} the dragon. How many jewels does the knight have?`,
    result: n1 + n2,
  };
}

export function makeCatDogFishQuestion() {
  const n1 = getRandomInt(50, 10);
  const n2 = getRandomInt(n1, -1 * n1);
  return {
    context: `A cat has ${n1} fish. A dog has ${moreLessThan(
      n2
    )} the cat. How many fish does the dog have?`,
    result: n1 + n2,
  };
}

export function makeAntQuestion() {
  const n1 = getRandomInt(50, 10);
  const n2 = -1 * getRandomInt(n1, 2);
  const n3 = getRandomInt(20, 2);
  return {
    context: `There are ${n1} ants at a picnic. If ${abs(
      n2
    )} ants leave but ${n3} more join, how many ants are at the picnic now?`,
    result: n1 + n2 + n3,
  };
}

export function makeFrogQuestion() {
  const n1 = getRandomInt(50, 10);
  const n2 = -1 * getRandomInt(n1, 2);
  const n3 = getRandomInt(20, 2);
  return {
    context: `A frog has ${n1} flies. It eats ${abs(
      n2
    )} but catches ${n3} more. How many flies does the frog have now?`,
    result: n1 + n2 + n3,
  };
}

function moreLessThan(num) {
  if (num > 0) {
    return `${num} more than`;
  }

  const lessWord = getRandomInt(2) === 1 ? "less" : "fewer";
  return `${abs(num)} ${lessWord} than`;
}

export function abs(num) {
  return Math.abs(num);
}
