import handwriting from "../lib/hc";
import { useEffect } from "react";
import "./MathInput.css";
import "./StackInput.css";

let anyStackCanvas, stackTextCanvas;
export default function StackInput({ children, question, onCheckResult }) {
  const anyStackCanvasSize = {
    width: 440,
    height: 200,
  };
  const stackTextCanvasSize = {
    width: 200,
    height: 200,
  };

  useEffect(() => {
    if (anyStackCanvas) {
      anyStackCanvas.erase();
      anyStackCanvas.destroy();
      anyStackCanvas = null;
    }
    anyStackCanvas = new handwriting.Canvas(
      document.getElementById("anyStackCanvas"),
      2
    );

    anyStackCanvas.setOptions({
      language: "en",
      numOfReturn: 1,
    });

    const { width, height } = anyStackCanvasSize;
    anyStackCanvas.cxt.canvas.width = width;
    anyStackCanvas.cxt.canvas.height = height;
    anyStackCanvas.set_Undo_Redo(true, true);
  }, [question?.id]);

  useEffect(() => {
    if (stackTextCanvas) {
      stackTextCanvas.erase();
      stackTextCanvas.destroy();
      stackTextCanvas = null;
    }
    stackTextCanvas = new handwriting.Canvas(
      document.getElementById("stackTextCanvas"),
      2
    );

    stackTextCanvas.setOptions({
      language: "en",
      numOfReturn: 1,
    });

    const { width, height } = stackTextCanvasSize;
    stackTextCanvas.cxt.canvas.width = width;
    stackTextCanvas.cxt.canvas.height = height;
    stackTextCanvas.set_Undo_Redo(true, true);
  }, [question?.id]);

  function clearStack() {
    anyStackCanvas.erase();
  }

  function clearCanvas() {
    stackTextCanvas.erase();
  }

  async function checkResult() {
    let answer = await stackTextCanvas.recognize();

    const result = question.isCorrect(answer[0]);
    // console.log("answer", answer);
    // console.log(result);

    if (result) {
      stackTextCanvas.destroy();
      stackTextCanvas = null;
    }

    onCheckResult(result);
  }

  function test(result) {
    console.log(result);
    const isCorrect = question.isCorrect(result);
    onCheckResult(isCorrect);
  }

  return (
    <div className="inputPanel">
      <div className="controllerContainer">
        {children}
        <button onClick={clearStack} style={{ background: "orange" }}>
          <i className="fa fa-eraser"></i>
        </button>
        <span style={{ flexGrow: 1 }} />
        <button onClick={clearCanvas} style={{ background: "orange" }}>
          <i className="fa fa-eraser"></i>
        </button>
        <button
          onClick={checkResult}
          style={{ background: "#b71c1c", color: "white" }}
        >
          <i
            className="fa-solid fa-circle-half-stroke"
            style={{ transform: "rotate(-90deg)" }}
          ></i>
        </button>
      </div>
      <div className="canvasContainer">
        <div className="stackEquationContainer">
          <canvas
            id="anyStackCanvas"
            className="anyStackCanvas layer1"
            width={anyStackCanvasSize.width}
            height={anyStackCanvasSize.height}
            style={{ cursor: "crosshair" }}
          ></canvas>
          {question.renderInput()}
        </div>
        <div>
          <canvas
            id="stackTextCanvas"
            className="stackTextCanvas layer1"
            width={stackTextCanvasSize.width}
            height={stackTextCanvasSize.height}
            style={{ cursor: "crosshair" }}
          ></canvas>
        </div>
      </div>
    </div>
  );
}
