import { talk, talkMath } from "../speaker/Speaker";
import {
  getRandomInt,
  getRandomItem,
  getRandomOperation,
  minus,
  number2words,
  pluralUnit,
  plus,
} from "../utils";
import { toNumber } from "./QuestionUtil";
import "./MoneyQuestion.css";

function MoneyWordQuestion() {
  const bill1 = getRandomInt(12, 1);
  const bill5 = getRandomInt(9, 1);
  const bill10 = getRandomInt(6);
  const subject = getRandomItem(["Moon", "Luna"]);
  const subject2 = getRandomItem(["Mom", "Dad", "Grandma", "Grandpa"]);

  const part2 =
    bill10 > 0 &&
    `${subject2} gives ${pluralUnit(bill10, "$10 bill", number2words)}.`;

  const text = `${subject} has ${pluralUnit(
    bill1,
    "$1 bill",
    number2words
  )} and ${pluralUnit(
    bill5,
    "$5 bill",
    number2words
  )}. ${part2} How much money does ${subject} have?`;
  return {
    question: <div>{text}</div>,
    text,
    result: bill1 + bill5 * 5 + bill10 * 10,
  };
}

function CoinQuestion() {
  const text = "What is the total value of these coins in cents?";

  const coinView = {
    cent: {
      view: <img src="/assets/coins/cent.png" width="50px" />,
      value: 1,
    },
    nickel: {
      view: <img src="/assets/coins/nickel.png" width="58.75px" />,
      value: 5,
    },
    dime: {
      view: <img src="/assets/coins/dime.png" width="47.5px" />,
      value: 10,
    },
    quarter: {
      view: <img src="/assets/coins/quarter.png" width="65px" />,
      value: 25,
    },
  };

  function randomCoins(extra) {
    return getRandomItem(["cent", "nickel", "dime", "quarter", extra]);
  }

  const coins = [
    randomCoins(),
    randomCoins(),
    randomCoins(""),
    randomCoins(""),
    randomCoins(""),
    randomCoins(""),
    randomCoins(""),
  ];

  const questionView = (
    <div className="coinQuestion">
      <div>{text}</div>
      <div className="coinView">
        {coins.map((coin) => coinView[coin]?.view)}
      </div>
    </div>
  );

  const result = coins.reduce((prev, coin) => {
    if (coin) {
      return (prev += coinView[coin]?.value);
    }
    return prev;
  }, 0);

  return {
    question: questionView,
    text,
    result,
  };
}

export function MoneyQuestion({ id = crypto.randomUUID() }) {
  // const q = MoneyWordQuestion();
  const q = getRandomItem([CoinQuestion, CoinQuestion, MoneyWordQuestion])();

  function isCorrect(answer) {
    return getResult() == toNumber(answer.replaceAll(" ", ""));
  }

  function getResult() {
    return q.result;
  }

  function getQuestion() {
    return q.question;
  }

  function getHints() {
    return [];
  }

  function renderHints(className, onClick) {
    return getHints().map((hint, id) => (
      <div
        key={`hint-${id}`}
        className={`hint ${className}`}
        onClick={() => onClick(hint)}
      >
        {hint}
      </div>
    ));
  }

  function renderQuestion(className, onClick) {
    return <div className="questionBubble bubble">{getQuestion()}</div>;
  }

  async function read() {
    // await talk(getHints()[0]);
    await talk(q.text);
  }

  return {
    id,
    type: "DRAFT_QUESTION",
    isCorrect,
    getQuestion,
    getHints,
    renderHints,
    renderQuestion,
    read,
  };
}
