import { getRandomInt } from "../utils";

let speakerReady = false;
let speakerVoice;

function setSpeech() {
  if (speakerReady) {
    return;
  }

  return new Promise((resolve, reject) => {
    let synth = window.speechSynthesis;
    let id;

    id = setInterval(() => {
      if (synth.getVoices().length !== 0) {
        speakerReady = true;
        resolve(synth.getVoices());
        clearInterval(id);
      }
    }, 10);
  });
}

function getVoice() {
  if (speakerVoice) return speakerVoice;

  var voices = window.speechSynthesis.getVoices();
  const voice = "Microsoft Ana Online (Natural) - English (United States)";
  speakerVoice = voices.find((v) => v.name === voice);
  return speakerVoice;
}

function getRandomVoice() {
  const voices = [
    "Microsoft AvaMultilingual Online (Natural) - English (United States)",
    "Microsoft AndrewMultilingual Online (Natural) - English (United States)",
    "Microsoft EmmaMultilingual Online (Natural) - English (United States)",
    "Microsoft BrianMultilingual Online (Natural) - English (United States)",
    "Microsoft Ava Online (Natural) - English (United States)",
    "Microsoft Andrew Online (Natural) - English (United States)",
    "Microsoft Emma Online (Natural) - English (United States)",
    "Microsoft Brian Online (Natural) - English (United States)",
    "Microsoft Ana Online (Natural) - English (United States)",
    "Microsoft Aria Online (Natural) - English (United States)",
    "Microsoft Christopher Online (Natural) - English (United States)",
    "Microsoft Eric Online (Natural) - English (United States)",
    "Microsoft Guy Online (Natural) - English (United States)",
    "Microsoft Jenny Online (Natural) - English (United States)",
    "Microsoft Michelle Online (Natural) - English (United States)",
    "Microsoft Roger Online (Natural) - English (United States)",
    "Microsoft Steffan Online (Natural) - English (United States)",
  ];
  const index = getRandomInt(voices.length - 1);
  return voices[index];
}

function getSpeaker() {
  var msg = new SpeechSynthesisUtterance();

  msg.voice = getVoice();
  msg.rate = 0.9; // [0.1 - 10]
  msg.pitch = 1; // [0 - 2]
  return msg;
}

export async function Speaker() {
  await setSpeech();

  const speaker = getSpeaker();
  function speak(text) {
    speaker.text = text;
    speechSynthesis.speak(speaker);
  }
  return {
    speak,
  };
}

export async function talk(text) {
  text && (await Speaker()).speak(text?.replace(/_.*_/, ", blank,"));
}

export async function talkMath(text) {
  text && (await talk(text?.replaceAll("-", "minus")));
}
