import { talk } from "../speaker/Speaker";
import { getRandomItem } from "../utils";
import { PuzzleQuestionView } from "./PuzzleQuestionView";

export function PuzzleQuestion({ id = crypto.randomUUID() }) {
  let result = false;

  const imageId = getRandomItem([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
  const imageUrl = `/assets/img/${imageId}.jpg`;

  function isCorrect() {
    const result = getResult();
    return result;
  }

  function getResult() {
    return result;
  }

  function getQuestion() {
    return "";
  }

  function getHints() {
    return ["Solve this puzzle"];
  }

  function setResult() {
    result = true;
  }

  function renderHints(className, onClick) {
    return getHints().map((hint, id) => (
      <div
        key={`hint-${id}`}
        className={`hint ${className}`}
        onClick={() => onClick(hint)}
      >
        {hint}
      </div>
    ));
  }

  function renderQuestion(className, onClick) {
    return (
      <div className="questionBubble bubble">
        <PuzzleQuestionView
          id={id}
          imageUrl={imageUrl}
          onComplete={() => {
            setResult();
          }}
        />
      </div>
    );
  }

  async function read() {
    await talk(getHints()[0]);
  }

  return {
    id,
    type: "NO_INPUT",
    isCorrect,
    getQuestion,
    getHints,
    renderHints,
    renderQuestion,
    read,
  };
}
